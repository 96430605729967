import React, { useEffect, useState } from "react";
import "./ProfileSelector.css";
import FilterListIcon from "@mui/icons-material/FilterList";
import SearchIcon from "@mui/icons-material/Search";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import Dropdown from "react-bootstrap/Dropdown";
import axios from "axios";

// IMAGE IMPORT
import View from "../../Images/view-grid.png";
import View2 from "../../Images/Group8926.png";
import Cards1img from "../../Images/Rectangle10.png";
import Cards2img from "../../Images/Rectangle11.png";
import Cards3img from "../../Images/Rectangle12.png";
import Filtericon from "../../Images/filter-vector.png";
import Refresh from "../../Images/Reload-vector.png";
import Viewgrid from "../../Images/view-grid.png";
import Viewgrid2 from "../../Images/Group8926.png";

//IMPORT CARDS

import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { Grid, IconButton, Paper } from "@mui/material";
import { IoMdArrowDropdown } from "react-icons/io";
import { AiFillHeart, AiFillThunderbolt, AiOutlineHeart } from "react-icons/ai";
import { FallingLines } from "react-loader-spinner";
import { Link } from "react-router-dom";
import { get_all_nft_contract_ } from "../../Web3/Contract";
import Skeleton from "react-loading-skeleton";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: "2px solid black",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    marginTop: "5px",
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "18ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

function ProfileSelector({
  URL,
  IPFS_image_baseUR,
  UserWallet,
  create_Single_Nft_Contract,
  add_Favorate,
}) {
  const [cardimg, setCardimg] = useState([]);
  const [heart, setHeart] = useState(false);

  const [creatednfts, setCreatedNfts] = useState();

  useEffect(() => {
    const init = async () => {
      OwnNFTs();
    };
    init();
  }, [UserWallet]);

  const OwnNFTs = () => {
    const nfts = [];
    axios
      .post(`${URL}/nftbycreateor`, {
        wallet: UserWallet,
      })
      .then(async (response) => {
        for (let i = 0; i < response.data.length; i++) {
          if (response.data[i].isNFTMinted && response.data[i].isCustom) {
            const contractValidator = await get_all_nft_contract_(
              response.data[i].NFTContract
            );

            const uri = await contractValidator.methods.baseURI().call();
            const nftname = await contractValidator.methods.name().call();
            const Datafromserver = await axios
              .post(`${URL}/callcorsapi`, {
                uri: `${uri}${response.data[i].tokenID}.json`,
              })
              .then((res) => {
                return res.data;
              });
            const collectionname = await axios
              .post(`${URL}/collectionbyname`, {
                name: response.data[i].collectionID,
              })
              .then((res) => {
                return res.data.name;
              });
            response.data[i].collectionname = collectionname;
            response.data[i].name = nftname;
            response.data[i].data = Datafromserver;
            response.data[i].contractanimation = Datafromserver.animation_url;
            response.data[i].contractimage = Datafromserver.image;

            nfts.push(response.data[i]);
          } else if (
            response.data[i].isNFTMinted &&
            response.data[i].airDropNFT
          ) {
            console.log("custom ", response.data[i].airDropNFT);
            const contractValidator = await get_all_nft_contract_(
              response.data[i].NFTContract
            );
            let uri = await contractValidator.methods.baseURI().call();
            console.log("uri", uri)
            if(uri === "" || uri === undefined){
              uri = await contractValidator.methods.initialURI().call();
            }
            const nftname = await contractValidator.methods.name().call();
           
            const Datafromserver = await axios.post(`${URL}/callcorsapi`, {
                uri: `${uri}${response.data[i].tokenID}.json`,
              })
              .then((res) => {
                return res.data;
              });
            const collectionname = await axios
              .post(`${URL}/collectionbyname`, {
                name: response.data[i].collectionID,
              })
              .then((res) => {
                return res.data.name;
              });
            response.data[i].collectionname = collectionname;
            response.data[i].name = nftname;
            response.data[i].data = Datafromserver;
            response.data[i].contractanimation = Datafromserver.animation_url;
            response.data[i].contractimage = Datafromserver.image;
            nfts.push(response.data[i]);
          } else {
            const collectionname = await axios
              .post(`${URL}/collectionbyname`, {
                name: response.data[i].collectionID,
              })
              .then((res) => {
                return res.data.name;
              });
            response.data[i].collectionname = collectionname;
            nfts.push(response.data[i]);
          }
        }
        setCreatedNfts(nfts);
      });
  };

  const refreshData = () => {
    OwnNFTs();
  };

  const searchNFTS = async (name) => {
    const nfts = [];
    axios
      .post(`${URL}/filternftbynameowner`, {
        name: name,
        wallet: UserWallet,
      })
      .then(async (response) => {
        for (let i = 0; i < response.data.length; i++) {
          if (response.data[i].isNFTMinted && response.data[i].isCustom) {
            console.log("custom ", response.data[i].isCustom);
            const contractValidator = await get_all_nft_contract_(
              response.data[i].NFTContract
            );

            const uri = await contractValidator.methods.baseURI().call();
            const nftname = await contractValidator.methods.name().call();

            const Datafromserver = await axios
              .post(`${URL}/callcorsapi`, {
                uri: `${uri}${response.data[i].tokenID}.json`,
              })
              .then((res) => {
                return res.data;
              });
            const collectionname = await axios
              .post(`${URL}/collectionbyname`, {
                name: response.data[i].collectionID,
              })
              .then((res) => {
                return res.data.name;
              });
            response.data[i].collectionname = collectionname;
            response.data[i].name = nftname;
            response.data[i].data = Datafromserver;
            response.data[i].contractanimation = Datafromserver.animation_url;
            response.data[i].contractimage = Datafromserver.image;

            nfts.push(response.data[i]);
          } else if (
            response.data[i].isNFTMinted &&
            response.data[i].airDropNFT
          ) {
            console.log("custom ", response.data[i].airDropNFT);
            const contractValidator = await get_all_nft_contract_(
              response.data[i].NFTContract
            );
            const uri = await contractValidator.methods.baseURI().call();
            const nftname = await contractValidator.methods.name().call();

            const Datafromserver = await axios
              .post(`${URL}/callcorsapi`, {
                uri: `${uri}${response.data[i].tokenID}.json`,
              })
              .then((res) => {
                return res.data;
              });
            const collectionname = await axios
              .post(`${URL}/collectionbyname`, {
                name: response.data[i].collectionID,
              })
              .then((res) => {
                return res.data.name;
              });
            response.data[i].collectionname = collectionname;
            response.data[i].name = nftname;
            response.data[i].data = Datafromserver;
            response.data[i].contractanimation = Datafromserver.animation_url;
            response.data[i].contractimage = Datafromserver.image;
            nfts.push(response.data[i]);
          } else {
            const collectionname = await axios
              .post(`${URL}/collectionbyname`, {
                name: response.data[i].collectionID,
              })
              .then((res) => {
                return res.data.name;
              });
            response.data[i].collectionname = collectionname;
            nfts.push(response.data[i]);
          }
        }
        setCreatedNfts(nfts);
      });
  };

  return (
    <>
      <Grid container>
        <Grid item xxl={3} xl={3} lg={3} md={12} sm={12} xs={12}>
          <div className="m-q-a-p-r-1_1200 ">
            <Button
              className="refresh-button collection-filter b-c-t b-1"
              onClick={() => refreshData()}
            >
              <img src={Refresh} alt="" />
            </Button>
          </div>
        </Grid>
        <Grid item xxl={9} xl={9} lg={9} md={12} sm={12} xs={12}>
          <div className="search-nav search-text-field m-y-1 b-1">
            <div className="input-nav ">
              <Paper
                component="form"
                sx={{
                  p: "2px 4px",
                  display: "flex",
                  alignItems: "center",
                  width: 400,
                }}
                className="collection-filter"
                id="form-search-input-collection"
              >
                <IconButton
                  type="button"
                  sx={{ p: "10px" }}
                  aria-label="search"
                >
                  <SearchIcon id="search-icon" />
                </IconButton>
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Search by NFTs"
                  id="search-icon-text"
                  onChange={(e) => searchNFTS(e.target.value)}
                />
              </Paper>
            </div>
          </div>
        </Grid>
      </Grid>
      {/* <div className="row py-3 pb-5">
          <div className="col-lg-1 col-md-1 col-sm-1 col-1 py-3">
            <span>
              <FilterListIcon />
            </span>
          </div>
          <div className="col-lg-7 col-md-11 col-sm-11 col-11  py-3">
            <Search className="search-00">
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search by name"
                inputProps={{ "aria-label": "search" }}
              />
            </Search>
          </div>
          <div className="col-lg-2 col-md-6 col-sm-6 col-6 py-3">
            {" "}
            <Dropdown>
              <Dropdown.Toggle
                variant="success"
                id="dropdown-basic recently-listed-dropdown"
                className="profile-recently"
              >
                Recently Listed
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="col-lg-2 col-md-6 col-sm-6 col-6 py-2  py-3  ">
            <span className="view-bu">
              <img src={View} alt="" className="vv-bu" />
              <img src={View2} alt="" />
            </span>
          </div>
        </div> */}

      <div className="container-fluid">
        <div className="row mb-5">
          {creatednfts ? (
            creatednfts.map((res) => {
              return (
                <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                  <Card className="profile-card">
                    <Card.Img
                      variant="top"
                      src={`${
                        res.contractimage ? res.contractimage : res.imageURL
                      }`}
                    />
                    <Card.Body>
                      <div className="d-f j-c-s-b a-i-c">
                        <div className="">
                          <Card.Title className="card-head">
                            {res.name}
                          </Card.Title>
                          <Card.Text>{res.collectionname}</Card.Text>
                        </div>
                        <div
                          className=""
                          onClick={async () => {
                            const data = await add_Favorate(
                              res._id,
                              res.liked.includes(UserWallet)
                            );
                            if (data) {
                              refreshData();
                            }
                          }}
                        >
                          {" "}
                          {res.liked.includes(UserWallet) ? (
                            <AiFillHeart className="fill-heart-like f-s-1_5" />
                          ) : (
                            <AiOutlineHeart className="unfill-heart-like f-s-1_5" />
                          )}
                        </div>
                      </div>
                    </Card.Body>
                    <Link to={`/assets/${res._id}`}>
                      <div className="add-cart-section ">
                        <span className="add-sec">View</span>
                      </div>
                    </Link>
                    {/* {res.isNFTMinted || res.Owner != UserWallet ? (
                          <Link to={`/assets/${res._id}`}>
                            <div className="add-cart-section ">
                              <span className="add-sec">View</span>
                            </div>
                          </Link>
                        ) : (
                          <div
                            className="add-cart-section "
                            onClick={async() =>
                              {const data = await create_Single_Nft_Contract(
                                res.nftType,
                                res.supply,
                                res._id,
                                res.name,
                                res.metadata,
                                res.to721,
                                res.symbol,
                                res.totalMinted
                              )
                              if(data){
                                refreshData()
                              }
                            }
                            }
                          >
                            <span className="add-sec">Generate and mint</span>
                            <span className="add-sec buy">
                              <AiFillThunderbolt className="buy-thunder" />
                              Buy now
                            </span>
                          </div>
                        )} */}
                  </Card>
                </div>
              );
            })
          ) : creatednfts === undefined ? (
            <>
              <div className="row">
                <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                  {" "}
                  <div className="p-x-0_5 p-y-1">
                    <Link to="" className="">
                      <div className="">
                        {/* upar wali classname mai notable_card className daalni hai connection ke waqt */}

                        <div className="">
                          {" "}
                          {/* <img
                    src="https://i.seadn.io/gcs/files/c9763bb78f9b1581d95551618be578fa.png?auto=format&dpr=1&h=500&fr=1"
                    alt=""
                    className="bt_br  "
                  /> */}
                          <Skeleton
                            height={200}
                            width="100%"
                            baseColor="#E4E9EB"
                            highlightColor="#fff"
                            className="border_rad bt_br"
                          />
                        </div>
                        <div className="notable_card p-x-1 ">
                          <div className=" p-y-1 f-w-600">
                            <Skeleton
                              baseColor="#E4E9EB"
                              highlightColor="#fff"
                              count={1}
                            />
                            {/* &nbsp;{" "}
                    <img src={tick} className="d-i-b w-1_25" /> */}
                          </div>
                          <div className="row p-b-1">
                            <div className="col">
                              <div className="f-s-0_8">
                                {" "}
                                <Skeleton
                                  baseColor="#E4E9EB"
                                  highlightColor="#fff"
                                  count={2}
                                />
                              </div>
                              {/* <div className=" f-w-600">88 PRIME</div> */}
                            </div>
                            <div className="col">
                              <div className="f-s-0_8">
                                <Skeleton
                                  baseColor="#E4E9EB"
                                  highlightColor="#fff"
                                  count={2}
                                />
                              </div>
                              {/* <div className=" f-w-600">455 MAAL</div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                  {" "}
                  <div className="p-x-0_5 p-y-1">
                    <Link to="" className="">
                      <div className="">
                        {/* upar wali classname mai notable_card className daalni hai connection ke waqt */}

                        <div className="">
                          {" "}
                          {/* <img
                    src="https://i.seadn.io/gcs/files/c9763bb78f9b1581d95551618be578fa.png?auto=format&dpr=1&h=500&fr=1"
                    alt=""
                    className="bt_br  "
                  /> */}
                          <Skeleton
                            height={200}
                            width="100%"
                            baseColor="#E4E9EB"
                            highlightColor="#fff"
                            className="border_rad bt_br"
                          />
                        </div>
                        <div className="notable_card p-x-1 ">
                          <div className=" p-y-1 f-w-600">
                            <Skeleton
                              baseColor="#E4E9EB"
                              highlightColor="#fff"
                              count={1}
                            />
                            {/* &nbsp;{" "}
                    <img src={tick} className="d-i-b w-1_25" /> */}
                          </div>
                          <div className="row p-b-1">
                            <div className="col">
                              <div className="f-s-0_8">
                                {" "}
                                <Skeleton
                                  baseColor="#E4E9EB"
                                  highlightColor="#fff"
                                  count={2}
                                />
                              </div>
                              {/* <div className=" f-w-600">88 PRIME</div> */}
                            </div>
                            <div className="col">
                              <div className="f-s-0_8">
                                <Skeleton
                                  baseColor="#E4E9EB"
                                  highlightColor="#fff"
                                  count={2}
                                />
                              </div>
                              {/* <div className=" f-w-600">455 MAAL</div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                  {" "}
                  <div className="p-x-0_5 p-y-1">
                    <Link to="" className="">
                      <div className="">
                        {/* upar wali classname mai notable_card className daalni hai connection ke waqt */}

                        <div className="">
                          {" "}
                          {/* <img
                    src="https://i.seadn.io/gcs/files/c9763bb78f9b1581d95551618be578fa.png?auto=format&dpr=1&h=500&fr=1"
                    alt=""
                    className="bt_br  "
                  /> */}
                          <Skeleton
                            height={200}
                            width="100%"
                            baseColor="#E4E9EB"
                            highlightColor="#fff"
                            className="border_rad bt_br"
                          />
                        </div>
                        <div className="notable_card p-x-1 ">
                          <div className=" p-y-1 f-w-600">
                            <Skeleton
                              baseColor="#E4E9EB"
                              highlightColor="#fff"
                              count={1}
                            />
                            {/* &nbsp;{" "}
                    <img src={tick} className="d-i-b w-1_25" /> */}
                          </div>
                          <div className="row p-b-1">
                            <div className="col">
                              <div className="f-s-0_8">
                                {" "}
                                <Skeleton
                                  baseColor="#E4E9EB"
                                  highlightColor="#fff"
                                  count={2}
                                />
                              </div>
                              {/* <div className=" f-w-600">88 PRIME</div> */}
                            </div>
                            <div className="col">
                              <div className="f-s-0_8">
                                <Skeleton
                                  baseColor="#E4E9EB"
                                  highlightColor="#fff"
                                  count={2}
                                />
                              </div>
                              {/* <div className=" f-w-600">455 MAAL</div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                  {" "}
                  <div className="p-x-0_5 p-y-1">
                    <Link to="" className="">
                      <div className="">
                        {/* upar wali classname mai notable_card className daalni hai connection ke waqt */}

                        <div className="">
                          {" "}
                          {/* <img
                    src="https://i.seadn.io/gcs/files/c9763bb78f9b1581d95551618be578fa.png?auto=format&dpr=1&h=500&fr=1"
                    alt=""
                    className="bt_br  "
                  /> */}
                          <Skeleton
                            height={200}
                            width="100%"
                            baseColor="#E4E9EB"
                            highlightColor="#fff"
                            className="border_rad bt_br"
                          />
                        </div>
                        <div className="notable_card p-x-1 ">
                          <div className=" p-y-1 f-w-600">
                            <Skeleton
                              baseColor="#E4E9EB"
                              highlightColor="#fff"
                              count={1}
                            />
                            {/* &nbsp;{" "}
                    <img src={tick} className="d-i-b w-1_25" /> */}
                          </div>
                          <div className="row p-b-1">
                            <div className="col">
                              <div className="f-s-0_8">
                                {" "}
                                <Skeleton
                                  baseColor="#E4E9EB"
                                  highlightColor="#fff"
                                  count={2}
                                />
                              </div>
                              {/* <div className=" f-w-600">88 PRIME</div> */}
                            </div>
                            <div className="col">
                              <div className="f-s-0_8">
                                <Skeleton
                                  baseColor="#E4E9EB"
                                  highlightColor="#fff"
                                  count={2}
                                />
                              </div>
                              {/* <div className=" f-w-600">455 MAAL</div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className="p-y-7  g-c f-w-600 t-a-c">NO DATA TO SHOW</div>
          )}
          {creatednfts && creatednfts.length <= 0 ? (
            <div className="p-y-7  g-c f-w-600 t-a-c">NO DATA TO SHOW</div>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
}

export default ProfileSelector;
