import React from "react";
import "./ProfileSetting.css";
import Navbarnft from "../Navbars/Navbarnft";
import Profilesettingslide from "./Profilesettingslide";
import Footer from "../Footer";

function ProfileSetting({ UserWallet, URL, IPFS_image_baseURL, 
  signMessage,
  logout }) {
  return (
    <>
      <div className="container-fluid"></div>
      <div>
        <Profilesettingslide
          UserWallet={UserWallet}
          URL={URL}
          IPFS_image_baseURL={IPFS_image_baseURL}
          signMessage={signMessage}
          logout={logout}
        />
      
      </div>
    </>
  );
}

export default ProfileSetting;
