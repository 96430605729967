import { createStore, action } from 'easy-peasy';

const MainStore = createStore({
    wallet_address: undefined,
    setWallet_address: action((state, payload) => {
        state.wallet_address = payload;
    }),
    authorization: "Basic " + window.btoa("2DAMudhgOkN62ZdPtEDTNh7ugcl" + ":" + "aed666c74886176af0e63a2594f14b85"),
    ipfs_Store_url:"https://ipfs.infura.io:5001/api/v0",
  });
  
  export default MainStore;
