import React from 'react';
import { useTranslation } from 'react-i18next';

function LanguageSelector() {
  const { i18n } = useTranslation();

  const handleLanguageChange = (event) => {
    i18n.changeLanguage(event.target.value);
  };

  return (
    <span className='para-clr'>
      <select onChange={handleLanguageChange} className='b-n b-c-t c-b para_clr bg_clr'>
        <option className='para_clr bg_clr' value="en">English</option>
        <option className='para_clr bg_clr' value="de">Indonesian </option>
        <option className='para_clr bg_clr' value="fr">Malay</option>
      </select>
    </span>
  );
}

export default LanguageSelector;
