import React, { useState } from "react";
import Footer from "../Footer";
import Home from "../Home";
import Navbarnft from "../Navbars/Navbarnft";

export default function Main({ UserWallet,  URL,  signMessage,
  logout }) {
  const [mode, setMode] = useState("dark");
  const toggleMode = () => {
    if (mode === "light") {
      setMode("dark");
      document.body.style.backgroundColor = "black";
      document.body.style.color = "white";
    } else {
      setMode("light");
      document.body.style.backgroundColor = "white";
      document.body.style.color = "black";
    }
  };
  return (
    <div>
      <Home mode={mode} toggleMode={toggleMode}  UserWallet={UserWallet}  URL={URL}  signMessage={signMessage}
            logout={logout} />
      <Footer />
    </div>
  );
}
