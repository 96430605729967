import React, { Component, useEffect, useState } from "react";
import Slider from "react-slick";

// import { FallingLines } from 'react-loader-spinner'
import { Link } from "react-router-dom";
import "./headerslider.css";
import tick from "./tick.png";
import Skeleton from "react-loading-skeleton";
import { LazyLoadImage } from "react-lazy-load-image-component";
import axios from "axios";
import { Tooltip } from "antd";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

const CustomPrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} custom-arrow-prev`}
      style={{ ...style, left: "-30px" }}
      onClick={onClick}
    >
      {/* Your custom previous arrow content */}
      <div className="custom-arrow-icon p-r-2_5">
        <IoIosArrowBack className="f-w-600" />
      </div>
    </div>
  );
};

// Custom next arrow component
const CustomNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} custom-arrow-next`}
      style={{ ...style, right: "-30px" }}
      onClick={onClick}
    >
      {/* Your custom next arrow content */}
      <div className="custom-arrow-icon  p-r-2">
        <IoIosArrowForward className="f-w-600" />
      </div>
    </div>
  );
};

const Responsive = ({ UserWallet, URL }) => {
  const [isHovered, setIsHovered] = useState(false);

  const [collection, setCollection] = useState([]);

  useEffect(() => {
    const init = async () => {
      axios
        .get(`${URL}/myallcollections`)
        .then((res) => {
          setCollection(res.data);
        })
        .catch(console.error);
    };
    init();
  }, []);
  var settings = {
    dots: false,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    arrows: isHovered,
    infinite: false,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          arrows: isHovered,
        },
      },
      {
        breakpoint: 1250,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          arrows: isHovered,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          arrows: isHovered,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          arrows: isHovered,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: isHovered,
        },
      },
    ],
  };

  return (
    <>
      <div
        className=""
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <>
          {collection.length > 5 ? (
            <Slider {...settings} className="">
              {collection
                ? collection.map((res) => {
                    return (
                      <div className="p-x-0_5 p-y-1">
                        <Link
                          to={`collection/${res.name
                            .toLowerCase()
                            .replace(/\s+/g, "-")}/${res.Collection_id}`}
                          className=""
                        >
                          <div className="">
                            <div className="">
                              <img
                                src={res.logoImage}
                                alt=""
                                className="notable_card_img  bt_br m-q-h-10 w-100 "
                              />
                            </div>
                            <div className="notable_card p-x-1 ">
                              <div className=" p-y-1 f-w-600">
                                {res.name} &nbsp;{" "}
                                {/* <img src={tick} className="d-i-b w-1_25" /> */}
                              </div>
                              <div className="row p-b-1">
                                <div className="col">
                                  <div className="f-s-0_8"> Floor</div>
                                  <div className=" f-w-600">---</div>
                                </div>
                                <div className="col">
                                  <div className="f-s-0_8">Total volume</div>
                                  <div className=" f-w-600">---</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </div>
                    );
                  })
                : ""}
            </Slider>
          ) : collection.length < 5 && collection != 0 ? (
            <div className="m-q-b-d-b">
              {collection &&
                collection.map((res) => {
                  return (
                    <div className="">
                      <div className="p-x-0_5 p-y-1">
                        <Link
                          to={`collectioninsidepage/${res.Collection_id}`}
                          className=""
                        >
                          <div className="">
                            <div className="">
                              {" "}
                              <img
                                src={res.logoImage}
                                alt=""
                                className="notable_card_img  bt_br m-q-h-10  m-q-w-15 "
                                loading="lazy"
                              />
                            </div>
                            <div className="notable_card p-x-1 ">
                              <div className=" p-y-1 f-w-600">
                                <Tooltip
                                  title={`${
                                    res.name.length > 17 ? res.name : ""
                                  }`}
                                >
                                  <span>
                                    {" "}
                                    {res.name.length > 17
                                      ? res.name.slice(0, 16) + "..."
                                      : res.name}{" "}
                                    &nbsp;{" "}
                                  </span>
                                </Tooltip>

                                {/* <img src={tick} className="d-i-b w-1_25" /> */}
                              </div>
                              <div className="row p-b-1">
                                <div className="col">
                                  <div className="f-s-0_8"> Floor</div>
                                  <div className=" f-w-600">---</div>
                                </div>
                                <div className="col">
                                  <div className="f-s-0_8 w-s-n-w">
                                    Total volume
                                  </div>
                                  <div className=" f-w-600">---</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </div>
                    </div>
                  );
                })}
            </div>
          ) : collection == 0 ? (
            <>
              {" "}
              <>
                <div className="row">
                  <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                    {" "}
                    <div className="p-x-0_5 p-y-1">
                      <Link to="" className="">
                        <div className="">
                          {/* upar wali classname mai notable_card className daalni hai connection ke waqt */}

                          <div className="">
                            {" "}
                            {/* <img
                    src="https://i.seadn.io/gcs/files/c9763bb78f9b1581d95551618be578fa.png?auto=format&dpr=1&h=500&fr=1"
                    alt=""
                    className="bt_br  "
                  /> */}
                            <Skeleton
                              height={200}
                              width="100%"
                              baseColor="#E4E9EB"
                              highlightColor="#fff"
                              className="border_rad bt_br"
                            />
                          </div>
                          <div className="notable_card p-x-1 ">
                            <div className=" p-y-1 f-w-600">
                              <Skeleton
                                baseColor="#E4E9EB"
                                highlightColor="#fff"
                                count={1}
                              />
                              {/* &nbsp;{" "}
                    <img src={tick} className="d-i-b w-1_25" /> */}
                            </div>
                            <div className="row p-b-1">
                              <div className="col">
                                <div className="f-s-0_8">
                                  {" "}
                                  <Skeleton
                                    baseColor="#E4E9EB"
                                    highlightColor="#fff"
                                    count={2}
                                  />
                                </div>
                                {/* <div className=" f-w-600">88 PRIME</div> */}
                              </div>
                              <div className="col">
                                <div className="f-s-0_8">
                                  <Skeleton
                                    baseColor="#E4E9EB"
                                    highlightColor="#fff"
                                    count={2}
                                  />
                                </div>
                                {/* <div className=" f-w-600">455 MAAL</div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                    {" "}
                    <div className="p-x-0_5 p-y-1">
                      <Link to="" className="">
                        <div className="">
                          {/* upar wali classname mai notable_card className daalni hai connection ke waqt */}

                          <div className="">
                            {" "}
                            {/* <img
                    src="https://i.seadn.io/gcs/files/c9763bb78f9b1581d95551618be578fa.png?auto=format&dpr=1&h=500&fr=1"
                    alt=""
                    className="bt_br  "
                  /> */}
                            <Skeleton
                              height={200}
                              width="100%"
                              baseColor="#E4E9EB"
                              highlightColor="#fff"
                              className="border_rad bt_br"
                            />
                          </div>
                          <div className="notable_card p-x-1 ">
                            <div className=" p-y-1 f-w-600">
                              <Skeleton
                                baseColor="#E4E9EB"
                                highlightColor="#fff"
                                count={1}
                              />
                              {/* &nbsp;{" "}
                    <img src={tick} className="d-i-b w-1_25" /> */}
                            </div>
                            <div className="row p-b-1">
                              <div className="col">
                                <div className="f-s-0_8">
                                  {" "}
                                  <Skeleton
                                    baseColor="#E4E9EB"
                                    highlightColor="#fff"
                                    count={2}
                                  />
                                </div>
                                {/* <div className=" f-w-600">88 PRIME</div> */}
                              </div>
                              <div className="col">
                                <div className="f-s-0_8">
                                  <Skeleton
                                    baseColor="#E4E9EB"
                                    highlightColor="#fff"
                                    count={2}
                                  />
                                </div>
                                {/* <div className=" f-w-600">455 MAAL</div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-md-4 col-lg-3 m-q-b-d-n">
                    {" "}
                    <div className="p-x-0_5 p-y-1">
                      <Link to="" className="">
                        <div className="">
                          {/* upar wali classname mai notable_card className daalni hai connection ke waqt */}

                          <div className="">
                            {" "}
                            {/* <img
                    src="https://i.seadn.io/gcs/files/c9763bb78f9b1581d95551618be578fa.png?auto=format&dpr=1&h=500&fr=1"
                    alt=""
                    className="bt_br  "
                  /> */}
                            <Skeleton
                              height={200}
                              width="100%"
                              baseColor="#E4E9EB"
                              highlightColor="#fff"
                              className="border_rad bt_br"
                            />
                          </div>
                          <div className="notable_card p-x-1 ">
                            <div className=" p-y-1 f-w-600">
                              <Skeleton
                                baseColor="#E4E9EB"
                                highlightColor="#fff"
                                count={1}
                              />
                              {/* &nbsp;{" "}
                    <img src={tick} className="d-i-b w-1_25" /> */}
                            </div>
                            <div className="row p-b-1">
                              <div className="col">
                                <div className="f-s-0_8">
                                  {" "}
                                  <Skeleton
                                    baseColor="#E4E9EB"
                                    highlightColor="#fff"
                                    count={2}
                                  />
                                </div>
                                {/* <div className=" f-w-600">88 PRIME</div> */}
                              </div>
                              <div className="col">
                                <div className="f-s-0_8">
                                  <Skeleton
                                    baseColor="#E4E9EB"
                                    highlightColor="#fff"
                                    count={2}
                                  />
                                </div>
                                {/* <div className=" f-w-600">455 MAAL</div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-md-4 col-lg-3 m-q-b-d-n">
                    {" "}
                    <div className="p-x-0_5 p-y-1">
                      <Link to="" className="">
                        <div className="">
                          {/* upar wali classname mai notable_card className daalni hai connection ke waqt */}

                          <div className="">
                            {" "}
                            {/* <img
                    src="https://i.seadn.io/gcs/files/c9763bb78f9b1581d95551618be578fa.png?auto=format&dpr=1&h=500&fr=1"
                    alt=""
                    className="bt_br  "
                  /> */}
                            <Skeleton
                              height={200}
                              width="100%"
                              baseColor="#E4E9EB"
                              highlightColor="#fff"
                              className="border_rad bt_br"
                            />
                          </div>
                          <div className="notable_card p-x-1 ">
                            <div className=" p-y-1 f-w-600">
                              <Skeleton
                                baseColor="#E4E9EB"
                                highlightColor="#fff"
                                count={1}
                              />
                              {/* &nbsp;{" "}
                    <img src={tick} className="d-i-b w-1_25" /> */}
                            </div>
                            <div className="row p-b-1">
                              <div className="col">
                                <div className="f-s-0_8">
                                  {" "}
                                  <Skeleton
                                    baseColor="#E4E9EB"
                                    highlightColor="#fff"
                                    count={2}
                                  />
                                </div>
                                {/* <div className=" f-w-600">88 PRIME</div> */}
                              </div>
                              <div className="col">
                                <div className="f-s-0_8">
                                  <Skeleton
                                    baseColor="#E4E9EB"
                                    highlightColor="#fff"
                                    count={2}
                                  />
                                </div>
                                {/* <div className=" f-w-600">455 MAAL</div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </>
            </>
          ) : (
            ""
          )}
        </>
      </div>
    </>
  );
};

export default Responsive;
