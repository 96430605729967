import * as React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

// IMPORT
import "./Profiletabs.css";
import ProfileSelector from "./ProfileSelector";
import Dropdown from "react-bootstrap/Dropdown";
import FilterListIcon from "@mui/icons-material/FilterList";
import SearchIcon from "@mui/icons-material/Search";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";

//IMAGES
import View from "../../Images/view-grid.png";
import View2 from "../../Images/Group8926.png";
import { Grid, IconButton, Paper } from "@mui/material";
import { Button } from "react-bootstrap";
import Filtericon from "../../Images/filter-vector.png";
import Refresh from "../../Images/Reload-vector.png";
import Viewgrid from "../../Images/view-grid.png";
import Viewgrid2 from "../../Images/Group8926.png";
import Profilecreatenft from "./Profilecreatenft";
import Profilefavouritenft from "./Profilefavouritenft";
import Loader from "./../../Components/Profile/Progressbar";
import { IoMdArrowDropdown } from "react-icons/io";
import { useEffect } from "react";
import {
  get_Factory_contract,
  get_Minting_contract_1155,
  get_Minting_contract_721,
} from "./../../Web3/Contract";
import axios from "axios";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: "2px solid black",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    marginTop: "5px",
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "18ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

export default function LabTabs({ URL, IPFS_image_baseUR, UserWallet }) {
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    const init = () => {
      get_Minting_contract_1155();
    };
    init();
  }, []);

  const [open, setOpen] = React.useState(false);
  const [percent, setPercent] = React.useState(0);

  const update_NFT_contract_address = async (id, address) => {
    axios
      .post(`${URL}/nftbyid/${id}`, {
        NFTContract: address,
        isNFTContractGenrated: true,
        last_update: new Date().getTime(),
      })
      .then((res) => {
        console.log(res);
        return true;
      })
      .catch((error) => {
        console.log(error);
        return false;
      });
  };

  const update_NFT_contract_isminted = async (id, tokenID,) => {
    console.log("Updating ID and minting status", id, tokenID)
    axios.post(`${URL}/nftbyid/${id}`, {
        isNFTMinted: true,
        tokenID: tokenID,
        last_update: new Date().getTime(),
      })
      .then((res) => {
        console.log(res);
        return true;
      })
      .catch((error) => {
        console.log(error);
        return false;
      });
  };

  const update_NFT_contract_isminted721 = async (id, tokenID, owner, totalMinted) => {
    console.log("Updating ID and minting status", id, tokenID)
    axios.post(`${URL}/nftbyid/${id}`, {
        isNFTMinted: true,
        tokenID: tokenID,
        Owner:owner,
        last_update: new Date().getTime(),
        totalMinted:totalMinted
      })
      .then((res) => {
        console.log(res);
        return true;
      })
      .catch((error) => {
        console.log(error);
        return false;
      });
  };

  const create_Single_Nft_Contract = async (type, supply, id, name, metadata, to721, symbol, totalMinted) => {
    // usnig current time as ID in place of id721
    try {
      const time = new Date().getTime() - 1;
      const factory = await get_Factory_contract();
      setOpen(true);
      // to generate the nft contract 

      if(type == 721){
        console.log("Single Minting")
        const data = await factory.methods.createNFT721(name, symbol, metadata, supply).send({ from: UserWallet })
        if (data.status) {
          setPercent(50);
          // get the user last generated nft contract
          const contracts = await factory.methods.getUserNFTContracts(UserWallet).call();
  
          //updating the nft contrac in database
          update_NFT_contract_address(id, contracts.at(-1));
  
          // impemented nft mint method to mint the nft
          const nft = await get_Minting_contract_721(contracts.at(-1));
          const data1 = await nft.methods.mint(to721, time).send({ from: UserWallet })
          if(data1.status){
            await update_NFT_contract_isminted721(id, time, to721, totalMinted+1);
            setPercent(100);
          }
          setOpen(false);
          return true;
        }
        return;
      }
      else{
        // this method create a 1155 nft and mint also with the given supply
        const data = await factory.methods.createNFT1155(name, symbol, time, supply, metadata).send({ from: UserWallet })
        if (data.status) {
          setPercent(50);
          const contracts = await factory.methods.getUserNFTContracts(UserWallet).call();
          await update_NFT_contract_address(id, contracts.at(-1));

          // await update_NFT_contract_isminted(id, time);
          // get the user last generated nft contract
  
          //updating the nft contrac in database
          // impemented nft mint method to mint the nft
          const nft = await get_Minting_contract_1155(contracts.at(-1));
          const data1 = await nft.methods.mintBatch([time], [supply]).send({ from: UserWallet })
          if(data1.status){
            await update_NFT_contract_isminted(id, time);
            setPercent(100);
          }
          setOpen(false);
          return true;
        }
        return;
      }
    } catch (error) {
      setOpen(false);
      console.log(error);
    }
  };

  const add_Favorate = async (id, checklikes) => {
    console.log(checklikes);
    let data;
    if (checklikes) {
      data = await axios
        .post(`${URL}/makedislike`, {
          wallet: UserWallet,
          id: id,
        })
        .then((res) => {
          return res;
        })
        .catch(console.error);
    } else {
      data = await axios
        .post(`${URL}/makelike`, {
          wallet: UserWallet,
          id: id,
        })
        .then((res) => {
          return res;
        })
        .catch(console.error);
    }
    return data;
  };


  

  return (
    <>
      <div className="container-fluid">
        <Box sx={{ width: "100%", typography: "body1" }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
                className="tab-select"
              >
                <Tab label="Collected" value="1" className="tab1" />
                <Tab label="Created" value="2" className="tab1" />
                <Tab label="Favorites" value="3" className="tab1" />
              </TabList>
            </Box>
            <TabPanel value="1">
              <ProfileSelector
                URL={URL}
                IPFS_image_baseUR={IPFS_image_baseUR}
                UserWallet={UserWallet}
                create_Single_Nft_Contract={create_Single_Nft_Contract}
                add_Favorate={add_Favorate}
              />
            </TabPanel>
            <TabPanel value="2">
              <Profilecreatenft
                URL={URL}
                IPFS_image_baseUR={IPFS_image_baseUR}
                UserWallet={UserWallet}
                create_Single_Nft_Contract={create_Single_Nft_Contract}
                add_Favorate={add_Favorate}
              />
            </TabPanel>
            <TabPanel value="3">
              <Profilefavouritenft
                URL={URL}
                IPFS_image_baseUR={IPFS_image_baseUR}
                UserWallet={UserWallet}
                create_Single_Nft_Contract={create_Single_Nft_Contract}
                add_Favorate={add_Favorate}
              />
            </TabPanel>
          </TabContext>
        </Box>
        {open ? <Loader percent={percent} /> : ""}
      </div>
    </>
  );
}
