import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import { I18nextProvider } from 'react-i18next';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { StoreProvider } from "easy-peasy";
import MainStore from "./Store"
import enTranslations from './locales/en.json';
import frTranslations from './locales/fr.json';
import deTranslations from './locales/de.json';

i18n
  .use(LanguageDetector)
  .init({
    resources: {
      en: { translation: enTranslations },
      fr: { translation: frTranslations },
      de: { translation: deTranslations },
    },
    fallbackLng: 'en', // Default language if translation not found
    debug: true, // Set to false in production
    interpolation: {
      escapeValue: false, // React already safes from XSS
    },
  });

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <StoreProvider store={MainStore}>
    <I18nextProvider i18n={i18n}>
      <App />
    </I18nextProvider>
  </StoreProvider>
  // <React.StrictMode>

  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
