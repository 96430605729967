import React, { useEffect, useState } from "react";
import {
  Grid,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import Card from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup";
import Merimg from "./Assestsimages/image.webp";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import "./Mergevv.css";
import {
  BsFillShareFill,
  BsThreeDots,
  BsEye,
  BsFillTagFill,
  BsStars,
} from "react-icons/bs";
import {
  AiFillThunderbolt,
  AiOutlineApartment,
  AiOutlineHeart,
  AiOutlineShoppingCart,
} from "react-icons/ai";
import { BiLinkExternal, BiTransfer } from "react-icons/bi";
import { SlGraph } from "react-icons/sl";
import { TfiMenuAlt } from "react-icons/tfi";
import { CiShare1 } from "react-icons/ci";
import logo_nft from "../../Images/logo_nft.jpg";
import Modal from "react-bootstrap/Modal";
import Accordion from "react-bootstrap/Accordion";
import { RxCross1 } from "react-icons/rx";
import Cardimd from "./Assestsimages/image.webp";
import Svgimg from "./Assestsimages/empty-asks.svg";
import Button from "react-bootstrap/Button";
import { ImSortAmountDesc, ImStatsBars2 } from "react-icons/im";
import { CgArrowsMergeAltH } from "react-icons/cg";
import { TbListDetails } from "react-icons/tb";
import { BsTwitter, BsFillCollectionFill } from "react-icons/bs";
import { FaEthereum } from "react-icons/fa";
import ProgressBar from "react-bootstrap/ProgressBar";
import Mergeimg from "./Assestsimages/merge.svg";
import { RiArrowUpDownFill } from "react-icons/ri";
import Dropdown from "react-bootstrap/Dropdown";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import DropdownButton from "react-bootstrap/DropdownButton";
import Mergecards from "./Mergecards";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import CardMedia from "@material-ui/core/CardMedia";
import {
  get_Marketplace_contract,
  get_Minting_contract_1155,
  get_Minting_contract_721,
  Approve,
  Allow,
  get_all_nft_contract_,
  get_ERC20_contract,
} from "../../Web3/Contract";
import {
  convertToLowercase,
  userBalanceOfChain,
  utils_fromowei,
  utils_towei,
} from "../../Web3/web3_api";
import { TOKENS, market_Place_Address } from "../../Web3/ABI";

import toast, { Toaster } from "react-hot-toast";
import Footer from "../Footer";
import moment from "moment";
import { IoIosArrowDown } from "react-icons/io";
import Navbarnft_global from "../Navbars/Navbarnft_global";

const notify = (msg) => toast.success(msg);
const warning = (msg) => toast.error(msg);

export default function Mergevv({ URL, UserWallet, signMessage, logout }) {
  const [open1, setOpen1] = React.useState(true);

  const handleToggle = () => {
    setOpen(!open);
  };

  const { id } = useParams();

  const [owner, setOwner] = useState();
  const [tokenID, setToeknID] = useState();
  const [imageURL, setImageURL] = useState(``);
  const [tokenAddress, setTokenAddress] = useState();
  const [attribute, setAttribute] = useState([]);
  const [receiveraddress, setReceiverAddresss] = useState();
  const [description, setDescription] = useState();
  const [time, setTime] = useState();
  const [type, setType] = useState();
  const [collection, setCollection] = useState();
  const [collectionMongoId, setCollectionMongoId] = useState();
  const [name, setName] = useState();
  const [show4, setShow4] = useState(false);
  const [show5, setShow5] = useState(false);
  const [show_minting, setShow_minting] = useState(false);
  const [show6, setShow6] = useState(false);
  const [show7, setShow7] = useState(false);
  const [price, setPrice] = useState(0);
  const [open, setOpen] = React.useState(false);
  const [percent, setPercent] = React.useState(0);
  const [offeramount, setOfferamount] = useState(0);
  const [isListed, setIsListed] = useState(false);
  const [listedprice, setListedprice] = useState(0);
  const [nftofsamecollection, setNFTofSameCollection] = useState();
  const [nfttransaction, setNFTTransaction] = useState();
  const [creatorName, setCreatorName] = useState();
  const [creatoraddress, setCreatorAddress] = useState();
  const [lastupdate, setLastUpdate] = useState();
  const [royalty_address, setRoyalty_address] = useState();
  const [Collection_id, setCollection_ID] = useState();
  const [quantityToSale, setQuantityToSale] = useState();
  const [pricePerNFT, setPricePerNFT] = useState();
  const [expireTime, setExpireTime] = useState();
  const [quantitytopuchase, setQuantityToPurchase] = useState();
  const [offerexpire, setOfferExpire] = useState();
  const [listingId, setListingID] = useState();
  const [quantityonsale, setQuantityonSale] = useState();
  const [quantityTopuchaseDirect, setQuantityToPurchaseDirect] = useState();
  const [balance_1155, setBalance_1155] = useState();
  const [seller, setSeller] = useState();
  const [isMusicalNFT, setIsMusicalNFT] = useState(false);
  const [musical_nft_data, setMusical_nft_data] = useState([]);
  const [nft, setNFT] = useState();
  const [totalMinted, setTotalMinted] = useState();
  const [totalSupple, setTotalSupply] = useState();
  const [listingTokenSymbol, setListingTokenSymbol] = useState();
  const [listingTokenBalance, setListingTokenBalance] = useState(0);
  const [listingTokenAddress, setListingTokenAddress] = useState("");
  const [offerID, setOfferID] = useState();
  const [newprice, setNewPrice] = useState();
  const [newTime, setNewTime] = useState();
  const [tokenForSale, setTokenForSale] = useState();
  const [royaltyFee, setRoyaltyFee] = useState();
  const [custom, setCustom] = useState();
  const [airdrop, setAirdrop] = useState();
  const [collection_name, setCollectionName] = useState();
  const [contract_Collection_id, setContract_Collection_id] = useState();
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);
  const handleClose_minting = () => setShow_minting(false);
  const handleShow_minting = () => setShow_minting(true);

  const handleClose6 = () => setShow6(false);
  const handleShow6 = () => setShow6(true);
  const handleShow7 = () => setShow7(!show7);
  useEffect(() => {
    axios
      .get(`${URL}/nftsinglebyid/${id}`)
      .then(async (res) => {
        setNFT(res.data);
        setTime(res.data.CreatedAt);
        setName(res.data.name);
        setCollectionMongoId(res.data._id);
        setCreatorAddress(res.data.Creator);
        setTokenAddress(res.data.NFTContract);
        setImageURL(res.data.imageURL);
        setToeknID(res.data.tokenID);
        setOwner(res.data.Owner);
        setCollection(res.data.collectionID);
        setLastUpdate(res.data.last_update);
        setType(res.data.nftType);
        setTotalMinted(res.data.totalMinted);
        setTotalSupply(res.data.supply);
        setAttribute(res.data.attributes);

        setDescription(res.data.description);
        setCustom(res.data.isCustom);
        setAirdrop(res.data.airDropNFT);
        // fetching creator name by its wallet address
        axios
          .post(`${URL}/getuser`, {
            wallet: res.data.Creator,
          })
          .then((res) => {
            setCreatorName(res.data[0].name);
          });

        axios
          .post(`${URL}/collectionbyname`, {
            name: res.data.collectionID,
          })
          .then((res) => {
            setRoyalty_address(res.data.royalty_address);
            setRoyaltyFee(res.data.fee);
            setContract_Collection_id(res.data.Contract_Collection_id);
            setCollectionName(res.data.name);
          });

        if (res.data.isCustom) {
          const contractValidator = await get_all_nft_contract_(
            res.data.NFTContract
          );
          const uri = await contractValidator.methods.baseURI().call();
          const nftname = await contractValidator.methods.name().call();
          const nftowner = await contractValidator.methods
            .ownerOf(res.data.tokenID)
            .call();
          setOwner(convertToLowercase(nftowner));
          const Datafromserver = await axios
            .post(`${URL}/callcorsapi`, {
              uri: `${uri}${res.data.tokenID}.json`,
            })
            .then((res) => {
              return res.data;
            });

          if (Datafromserver != "") {
            setName(nftname);
            setAttribute(Datafromserver.attributes);
            setImageURL(
              Datafromserver.animation_url
                ? Datafromserver.animation_url
                : Datafromserver.image
            );
            setDescription(Datafromserver.description);
          }
        } else if (res.data.airDropNFT) {
          const contractValidator = await get_all_nft_contract_(
            res.data.NFTContract
          );
          let uri = await contractValidator.methods.baseURI().call();
       
          if (uri === "" || uri === undefined) {
            uri = await contractValidator.methods.initialURI().call();
            console.log(uri);
       
          }
          if (typeof uri === "string") {
            if (uri[uri.length - 1] != "/") {
              uri = uri + "/";
            }
          }

          const nftname = await contractValidator.methods.name().call();
 
          const nftowner = await contractValidator.methods.ownerOf(res.data.tokenID).call();
  
          setOwner(convertToLowercase(nftowner));

          const Datafromserver = await axios.post(`${URL}/callcorsapi`, {
              uri: `${uri}${res.data.tokenID}.json`,
            })
            .then((res) => {
              return res.data;
            });
      
          if (Datafromserver != "") {
            setName(nftname);
            setAttribute(Datafromserver.attributes);
            setImageURL(Datafromserver.image ? Datafromserver.image : logo_nft);
            setDescription(Datafromserver.description);
          }
        } else {
          const contractValidator = await get_Minting_contract_1155(
            res.data.NFTContract
          );
          // const nftowner = await contractValidator.methods.owner().call();
          const balance = await contractValidator.methods
            .balanceOf(UserWallet, res.data.tokenID)
            .call();
          setBalance_1155(balance);
          setOwner("1155");
        }

        // const nft_1155_contract = await get_Minting_contract_1155(res.data.NFTContract);
        // const balance = await nft_1155_contract.methods.balanceOf(UserWallet, res.data.tokenID).call();
        // setBalance_1155(balance);

        // fetch all nfts of same collection excluding the current showing nft
        axios
          .post(`${URL}/nftbycollection/${id}`, {
            collection: res.data.collectionID,
          })
          .then(async (response) => {
            // console.log("collection ",response)
            // const marketplace = await get_Marketplace_contract();
            // const nfts = [];
            // for (let i = 0; i < response.data.length; i++) {
            //   if (response.data[i].isNFTMinted) {
            //     const listting = await marketplace.methods.getNFTListingByToken(response.data[i].NFTContract,response.data[i].tokenID).call();
            //     console.log(listting)
            // response.data[i].pricing = listting.price;
            // console.log(listting);
            // nfts.push(response.data[i]);
            // }
            // }
            // setNFTofSameCollection(nfts);
          });
      })
      .catch((error) => {
        console.log("Error", error);
      });

    axios
      .post(`${URL}/gettrx/${id}`)
      .then((res) => {
        setNFTTransaction(res.data);
      })
      .catch(console.error);
  }, [UserWallet, open, id]);

  const slice = (str) => {
    if (str) {
      const first = str.slice(0, 5);
      const second = str.slice(38);
      return first + "..." + second;
    }
  };

  const [alloffers, setAllOffers] = useState([]);

  useEffect(() => {
    const init = async () => {
      try {
        const get_Offers = [];
        const marketplace = await get_Marketplace_contract();

        const listting = await marketplace.methods
          .getListingsByNFT(tokenAddress, tokenID)
          .call();
        const listing_status = await marketplace.methods
          .NFTListings(listting[listting.length - 1])
          .call();

        setListingID(listing_status.listingId);
        if (listing_status.listingStatus === "1") {
          setIsListed(true);
          setSeller(convertToLowercase(listing_status.seller));
          setQuantityonSale(Number(listing_status.QuantityOnSale));
          setListedprice(listing_status.PricePerNFT / 10 ** 18);
          setPricePerNFT(listing_status.PricePerNFT )
          if (listing_status.tokenAdd === "0x0000000000000000000000000000000000000000"
          ) {
            setListingTokenSymbol("MAAL");
            const NativeBalance = await userBalanceOfChain(UserWallet);
            setListingTokenBalance(NativeBalance);
            setListingTokenAddress(listing_status.tokenAdd);
          } else {
            const tokenContract = await get_ERC20_contract(listing_status.tokenAdd);
            const name = await tokenContract.methods.name().call();
            const tokenbalance = await tokenContract.methods.balanceOf(UserWallet).call();
            const towio = await utils_fromowei(tokenbalance);
            setListingTokenBalance(towio);
            setListingTokenSymbol(name);
            setListingTokenAddress(listing_status.tokenAdd);
          }

          const offers = await marketplace.methods
            .getOffersByListing(listting[listting.length - 1])
            .call();
          for (let i = 0; i < offers.length; i++) {
            const offer = await marketplace.methods.offers(offers[i]).call();
            if (
              offer.isActive &&
              Number(offer.offerExpireTime) > new Date().getTime() / 1000
            ) {
              get_Offers.push(offer);
            }
          }
          setAllOffers(get_Offers);
        }
      } catch (error) {
        console.log(error);
      }
    };
    init();
  }, [tokenAddress, tokenID, UserWallet, open, id, Collection_id]);

  const updating_Nft_Listing_id = async () => {
    const marketplace = await get_Marketplace_contract();
    const listting = await marketplace.methods
      .getListingsByNFT(tokenAddress, tokenID)
      .call();
    try {
      axios
        .post(`${URL}/nftlistingID/${id}`, {
          NFT_Listing_id: Number(listting[0]),
        })
        .then((res) => {
          console.log(res);
        })
        .catch(console.error);
    } catch (error) {}
  };

  const updateContract_CollectionID = async (id) => {
    const data = await axios
      .post(`${URL}/addcontractcollections`, {
        Collection_id: collection,
        Contract_Collection_id: id,
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        console.log(error);
      });
    return data;
  };

  const sellNFT = async () => {
    try {
      let nft = undefined;
      let collectionid = 0;
      setOpen(true);
      const marketplace = await get_Marketplace_contract();
      let allContractidByUser = await marketplace.methods.getCollectionsByOwner(UserWallet).call();
      let Contract_CollectionID = await marketplace.methods.collectionIdToNFTAddress(tokenAddress).call();
     
      if (
        contract_Collection_id != Contract_CollectionID && !allContractidByUser.includes(contract_Collection_id)
      ) {
        console.log("Adding Collections");
        const addingCollection = await marketplace.methods
          .addCollection(royaltyFee, royalty_address)
          .send({ from: UserWallet });
        if (addingCollection.status) {
          const userCollection = await marketplace.methods
            .getCollectionsByOwner(UserWallet)
            .call();
          console.log("Collections", userCollection);
          updateContract_CollectionID(
            userCollection[userCollection.length - 1]
          );
        }
      }
      collectionid = await axios.post(`${URL}/collectionbyname`, {
          name: collection,
        })
        .then((res) => {
          return res.data.Contract_Collection_id;
        });

      if (type === 721) {
        nft = await get_Minting_contract_721(tokenAddress);
        const isapprove = await nft.methods.getApproved(tokenID).call();
        console.log("IS apporved", isapprove)
        if (isapprove != market_Place_Address) {
            const approve = await nft.methods.setApprovalForAll(market_Place_Address, true).send({ from: UserWallet });
          setPercent(50);
        }

        const data = await marketplace.methods
          .listNFT(
            collectionid,
            tokenAddress,
            tokenID,
            1,
            tokenForSale,
            price,
            expireTime
          )
          .send({ from: UserWallet });
        if (data.status) {
          // updating_Nft_Listing_id();
          setOpen(false);
          notify("Success");
        } else {
          setOpen(false);
          setPercent(0);
        }
      } else {
        if (Number(quantityToSale) > Number(balance_1155)) {
          warning("Insufficient balance");
          setOpen(false);
          return;
        }
        nft = await get_Minting_contract_1155(tokenAddress);
        const isapprove = await nft.methods
          .isApprovedForAll(UserWallet, market_Place_Address)
          .call();
        if (!isapprove) {
          setOpen(true);
          if(listingTokenAddress != "0x0000000000000000000000000000000000000000"){
            const approve = await nft.methods.setApprovalForAll(market_Place_Address, true).send({ from: UserWallet });
          }
          setPercent(50);
        }
        console.log("Listing NTS 1155");
        console.log(
          collectionid,
          tokenAddress,
          tokenID,
          quantityToSale,
          tokenForSale,
          price,
          expireTime
        );
        const data = await marketplace.methods
          .listNFT(
            collectionid,
            tokenAddress,
            tokenID,
            quantityToSale,
            tokenForSale,
            price,
            expireTime
          )
          .send({ from: UserWallet });
        if (data.status) {
          // updating_Nft_Listing_id();
          setPercent(100);
          setOpen(false);
          notify("Success");
        } else {
          setOpen(false);
          setPercent(0);
        }
      }
    } catch (error) {
      setOpen(false);
      console.log(error);
    }
  };

  const handlePrice = async (value) => {
    const number = await utils_towei(value);
    setPrice(number);
  };

  const updateOffer = async (newprice, newTime) => {
    try {
      setOpen(true);
      const number = await utils_towei(newprice);
      const time = Number(new Date(newTime).getTime() / 1000).toFixed(0);
      const marketplace = await get_Marketplace_contract();
      const data = await marketplace.methods
        .modifyOffer(number, time)
        .send({ from: UserWallet });
      if (data.status) {
        setOpen(false);
        notify("Success");
      }
    } catch (error) {
      console.log(error);
      setOpen(false);
    }
  };

  const update_owner = async (Offerer, price) => {
    axios
      .post(`${URL}/purhchased/${id}`, {
        Owner: Offerer,
        price: price,
        collectionID: collection,
        last_update: new Date().getTime(),
      })
      .then((res) => {
        console.log(res);
      })
      .catch(console.error);
  };

  const make_Offerr = async () => {
    try {
      setOpen(true);
      if (listingTokenAddress != "0x0000000000000000000000000000000000000000") {
        const value = await Allow(UserWallet, listingTokenAddress);
        console.log("approve value", value);
        if (Number(value) == 0) {
          await Approve(UserWallet, listingTokenAddress);
        }
      }
      

      const marketplace = await get_Marketplace_contract();
      const number = await utils_towei(offeramount);
      console.log(
        listingId,
        quantitytopuchase,
        number,
        offerexpire,
        listingTokenAddress
      );
     if(listingTokenAddress == "0x0000000000000000000000000000000000000000"){
      const data = await marketplace.methods.createOfferWithMAAL(listingId, quantitytopuchase, number, offerexpire).send({ from: UserWallet, value:number });
      if (data.status) {
        setOpen(false);
        notify("Success");
      } else {
        setOpen(false);
      }
     }
     else{
      const data = await marketplace.methods.createOffer(listingId, quantitytopuchase, number, offerexpire).send({ from: UserWallet });
      if (data.status) {
        setOpen(false);
        notify("Success");
      } else {
        setOpen(false);
      }
     }
    } catch (error) {
      console.log(error);
      setOpen(false);
    }
  };

  const accept_offer = async (offerer, price, id) => {
    try {
      setOpen(true);
      const marketplace = await get_Marketplace_contract();
      const data = await marketplace.methods
        .acceptOffer(id)
        .send({ from: UserWallet });
      if (data.status) {
        const a = await utils_fromowei(price);
        update_owner(offerer, a);
        await make_transaction("Sale", UserWallet, offerer, a);
        await make_transaction("Buy", offerer, UserWallet, a);
        setOpen(false);
        notify("Success");
      } else {
        setOpen(false);
      }
    } catch (error) {
      console.log(error);
      setOpen(false);
    }
  };

  const buy_nft = async () => {
    try {
      setOpen(true);
      if (listingTokenAddress != "0x0000000000000000000000000000000000000000") {
        const value = await Allow(UserWallet, listingTokenAddress);
        console.log("approve value", value);
        if (Number(value) == 0) {
          await Approve(UserWallet, listingTokenAddress);
        }
      }
      const a = await utils_towei(listedprice);
      const marketplace = await get_Marketplace_contract();
     if(listingTokenAddress == "0x0000000000000000000000000000000000000000"){
      const data = await marketplace.methods.buyNFTWithMAAL(listingId, quantityTopuchaseDirect).send({ from: UserWallet, value:pricePerNFT });
      if (data.status) {
        await update_owner(UserWallet, listedprice);
        await make_transaction("Buy", seller, UserWallet, listedprice);
        await make_transaction("Sale", UserWallet, seller, listedprice);
        setOpen(false);
        notify("Success");
      } else {
        setOpen(false);
      }
     }
     else{
      const data = await marketplace.methods.buyNFT(listingId, quantityTopuchaseDirect).send({ from: UserWallet });
      if (data.status) {
        await update_owner(UserWallet, listedprice);
        await make_transaction("Buy", seller, UserWallet, listedprice);
        await make_transaction("Sale", UserWallet, seller, listedprice);
        setOpen(false);
        notify("Success");
      } else {
        setOpen(false);
      }
     }
    } catch (error) {
      setOpen(false);
    }
  };

  const CancalOffer = async (offerID) => {
    try {
      const marketplace = await get_Marketplace_contract();
      const data = await marketplace.methods
        .cancelOffer(offerID)
        .send({ from: UserWallet });
      if (data.status) {
      }
    } catch (error) {}
  };

  const make_transaction = async (event_Type, from, to, value) => {
    axios
      .post(`${URL}/maketrx`, {
        nft_id: id,
        event: event_Type,
        nftContract: tokenAddress,
        from: from,
        to: to,
        value: value,
        time: new Date().getTime(),
      })
      .then((res) => {
        console.log(res);
      })
      .catch(console.error);
  };

  const price_to_wei = async (amount) => {
    try {
      const number = await utils_fromowei(amount);
      return number;
    } catch (error) {}
  };

  const Time_ago = (time) => {
    const date = moment(time).fromNow();
    return date;
  };

  const Time_data = (time) => {
    const date = moment(time).format("L");
    return date;
  };

  const sliceAddresss = (address) => {
    const first = address.slice(0, 5);
    const second = address.slice(38);
    return first + "..." + second;
  };

  const sliceAdd = (address) => {
    if (typeof address === "string") {
      const first = address.slice(0, 7);
      return first + "...";
    } else {
      return "Invalid address";
    }
  };

  const mintAgain = async (data) => {
    delete nft._id;
    nft.Owner = receiveraddress;
    setOpen(true);
    const nftcontract = await get_Minting_contract_721(tokenAddress);
    const data1 = await nftcontract.methods
      .mint(receiveraddress, new Date().getTime() + 5)
      .send({ from: UserWallet });
    if (data1.status) {
      console.log(nft);
      nft.tokenID = new Date().getTime() + 5;
      nft.last_update = new Date().getTime();
      await axios
        .post(`${URL}/makenftsagain`, nft)
        .then((res) => {
          console.log(res.data);
          notify("NFT created successfully");
          setOpen(false);
        })
        .catch((error) => {
          console.log(error);
          setOpen(false);
        });
    }
  };

  const sliceNum = (num) => {
    if (!num) {
      return;
    }
    const fir = num.toString().slice(0, 5);
    return fir + "...";
  };

  return (
    <>
      <Navbarnft_global
        UserWallet={UserWallet}
        signMessage={signMessage}
        logout={logout}
        URL={URL}
      />
      <Box className="container-kws">
        <div className="">
          {" "}
          {(owner === UserWallet && isListed) || seller == UserWallet ? (
            <Button
              variant="primary"
              // onClick={handleShow4}
              className="sell-button"
              id="button-sell-color"
            >
              Listed for Sale
            </Button>
          ) : owner == UserWallet && !isListed ? (
            <Button
              variant="primary"
              onClick={handleShow4}
              className="sell-button"
              id="button-sell-color"
            >
              List for Sell (720)
            </Button>
          ) : owner === "1155" && !isListed && balance_1155 > 0 ? (
            <Button
              variant="primary"
              onClick={handleShow4}
              className="sell-button"
              id="button-sell-color"
            >
              List for Sell (1155)
            </Button>
          ) : owner != UserWallet && isListed ? (
            <Button
              variant="primary"
              onClick={handleShow5}
              className="sell-button"
              id="button-sell-color"
            >
              Make offer
            </Button>
          ) : (
            <Button
              variant="primary"
              // onClick={handleShow5}
              className="sell-button"
              id="button-sell-color"
            >
              Not for Sale
            </Button>
          )}
        </div>
       
        <Grid container space={2}>
          <Grid xl={5} lg={5} md={12} sm={12} xs={12}>
            <div>
              {" "}
             
              <div className="p-x-0_5">
                {custom ? (
                  <article className="sc-57ceaf99-0 sc-2092e28b-0 kHrhvD bJqyHU item--frame item--media-frame m-b-3">
                    <div className="d-f a-i-c j-c-s-b pa-1">
                      <img
                        src="https://ipfs.io/ipfs/QmXCRTnM7N8HefWCJjpBkio4z1wA6J5SS6921mKtzBV4hv?filename=favicon.png"
                        className="w-2 b-r-50"
                      />

                      <div className="d-f a-i-c">
                        <BiLinkExternal className="f-s-1_25" />
                        {/* &nbsp; 0 &nbsp;
                        <AiOutlineHeart className="f-s-1_25" /> */}
                      </div>
                    </div>

                    <div className="sc-57ceaf99-0 sc-ce8ac90b-0 kHrhvD iPiVBj">
                      <div className="sc-57ceaf99-0 inwolf">
                        <div
                          height="100%"
                          width="100%"
                          className="sc-57ceaf99-0 ikUvYq"
                        >
                          <div className="sc-fabf517e-0 hObjOE item--media">
                            <div
                              height="100%"
                              width="100%"
                              className="sc-57ceaf99-0 sc-630fc9ab-0 sc-99655001-0 sc-4422a702-0 sc-dada3b88-0 jLlAQq bNkKFC dsowZP jZNcco bhxycR"
                            >
                              <div className="AssetMedia--animation">
                                <div className="sc-57ceaf99-0 sc-630fc9ab-0 sc-99655001-0 sc-4422a702-0 sc-dada3b88-0 kHrhvD bNkKFC dsowZP jZNcco bhxycR AssetMedia--playback-wrapper">
                                  {imageURL ? (
                                    <>
                                      <video
                                        autoPlay
                                        className="AssetMedia--video"
                                        controls
                                        controlsList="nodownload"
                                        loop
                                        playsInline
                                        // poster="https://i.seadn.io/gcs/files/cd3480224f20f93f996bc1c63c07371b.jpg?w=500&amp;auto=format"
                                        preload="metadata"
                                        style={{
                                          objectFit: "contain",
                                          borderRadius: "initial",
                                        }}
                                      >
                                        <source
                                          data-testid="AssetMedia--video"
                                          src={imageURL}
                                          type="video/mp4"
                                        />
                                      </video>
                                    </>
                                  ) : (
                                    <div>Loading video...</div>
                                  )}
                                  <div></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </article>
                ) : airdrop ? (
                  <article className="sc-57ceaf99-0 sc-2092e28b-0 kHrhvD bJqyHU item--frame item--media-frame m-b-3">
                    <div className="d-f a-i-c j-c-s-b pa-1">
                      <img
                        src="https://ipfs.io/ipfs/QmXCRTnM7N8HefWCJjpBkio4z1wA6J5SS6921mKtzBV4hv?filename=favicon.png"
                        className="w-2 b-r-50"
                      />

                      <div className="d-f a-i-c">
                        <BiLinkExternal className="f-s-1_25 c-p" />
                        {/* &nbsp; 0 &nbsp;
                        <AiOutlineHeart className="f-s-1_25" /> */}
                      </div>
                    </div>
                    <Card.Img
                      variant="top"
                      src={imageURL}
                      id="card-nft-main-image"
                      className="b-r-10 "
                    />
                  </article>
                ) : (
                  <article className="sc-57ceaf99-0 sc-2092e28b-0 kHrhvD bJqyHU item--frame item--media-frame m-b-3">
                    <div className="d-f a-i-c j-c-s-b pa-1">
                      <img
                        src="https://ipfs.io/ipfs/QmXCRTnM7N8HefWCJjpBkio4z1wA6J5SS6921mKtzBV4hv?filename=favicon.png"
                        className="w-2 b-r-50"
                      />

                      <div className="d-f a-i-c">
                        <BiLinkExternal className="f-s-1_25 c-p" />
                      </div>
                    </div>
                    <img src={imageURL} />
                  </article>
                )}
              </div>
            </div>
            <div className="container-fluid">
              <div className="boxes">
                <div className="bbb">
                  <ImSortAmountDesc /> <span>Description</span>
                </div>
                <div className="des">
                  <span>
                    By <strong>{creatorName}</strong>
                  </span>
                  <br /> 
                  <span>{creatoraddress ? sliceAdd(creatoraddress) : ""}</span>
                </div>
                <hr id="hr2" />
                <div className="accordion ">
                  <Accordion>
                    <Accordion.Item eventKey="0" className="b-n">
                      <Accordion.Header className="b-n">
                        <BsFillTagFill className="price-gr " />
                        Properties
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="row">
                          {attribute &&
                            attribute.map((res) => {
                              return (
                                <div className="col-12 col-sm-6 col-md-6 col-xl-4 col-xxl-4 col-xxxl-3  p-x-0_25 m-y-0_25">
                                  <div>
                                    <div className="properties-box">
                                      <span className="properties-na yellow_clr f-s-0_8">
                                        {res.trait_type
                                          ? res.trait_type
                                          : res.type}
                                      </span>
                                      <div className="merg f-s-0_8">
                                        {res.value}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          {attribute && attribute.length > 0 ? (
                            ""
                          ) : (
                            <div className="p-y-3 t-a-c">
                              NO data to show...
                            </div>
                          )}
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
                {/* <div className="accordion">
                  <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        <ImStatsBars2 className="price-gr" />
                        Levels
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="value-item">
                          <span className="value">Number</span>
                          <div className="numbers-bar">
                            <span className="value">25,600 of 29,963</span>
                          </div>
                        </div>
                        <div>
                          <ProgressBar now={60} />
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div> */}
                <hr id="hr2" />
                <div className="accordion ">
                  <Accordion>
                    <Accordion.Item eventKey="0" className="b-n">
                      <Accordion.Header>
                        <CgArrowsMergeAltH className="price-gr" />
                        About
                      </Accordion.Header>
                      <Accordion.Body>
                        <div>
                          <div className="d-flex">
                            {/* <div className="h-18 ">
                              <video  autoPlay loop>
                                <source
                                  src={imageURL}
                                  type="video/mp4"
                                  className="h-18 "
                                />
                                Your browser
                              </video>
                              </div>
                            */}

                            <div className="merge-details p-l-0 m-l-0">
                              {description}
                            </div>
                          </div>
                          <div className="social-icon d-flex ">
                            <div className="twitter">
                              <BsTwitter />
                            </div>
                            {/* <div className="eth">
                              <FaEthereum />
                            </div> */}
                            <div className="dot">
                              <BsThreeDots />
                            </div>
                          </div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
                <hr id="hr2" />
                <div className="accordion ">
                  <Accordion>
                    <Accordion.Item eventKey="0" className="b-n">
                      <Accordion.Header>
                        <TbListDetails className="price-gr" />
                        Details
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="tab">
                          <span>Contract Address</span>
                          <div
                            className="contract"
                            onClick={() => {
                              navigator.clipboard
                                .writeText(tokenAddress)
                                .then(() => {
                                  notify("Text copied to clipboard");
                                });
                            }}
                          >
                            <span>
                              {tokenAddress ? slice(tokenAddress) : ""}
                            </span>
                          </div>
                        </div>
                        <div className="tab">
                          <span>Token ID</span>
                          <div
                            className="contract"
                            onClick={() => {
                              navigator.clipboard
                                .writeText(tokenID)
                                .then(() => {
                                  notify("Text copied to clipboard");
                                });
                            }}
                          >
                            <span>{tokenID}</span>
                          </div>
                        </div>
                        <div className="tab">
                          <span>Token Standard</span>
                          <div className="contract">
                            <span>MAAL-{type}</span>
                          </div>
                        </div>
                        <div className="tab">
                          <span>Chain</span>
                          <div className="contract">
                            <span>MAAL MAINNET</span>
                          </div>
                        </div>
                        <div className="tab">
                          <span>Last Updated</span>
                          <div className="contract">
                            <span>{Time_ago(lastupdate)}</span>
                          </div>
                        </div>
                        {/* <div className="tab">
                          <span>Creator Earnings</span>
                          <div className="contract">
                            <span>2.5%</span>
                          </div>
                        </div> */}
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </div>
            </div>
          </Grid>
          <Grid xl={7} lg={7} md={12} sm={12} xs={12}>
            <div className="container ">
              <div className="row ">
                <div className="col-6  m-q-b-d-n">
                  <Link to={`/collection/${collection_name}/${collection}`}>
                    <span className="yellow_clr">{collection_name}</span>
                  </Link>
                </div>
                <div className="col-6 m-q-b-d-n">
                  <div className="ii">
                    <span>{/* <BsFillShareFill /> */}</span>
                    <span>{/* <BsThreeDots className="share" /> */}</span>
                  </div>
                </div>
                <div></div>
                <div>
                  <div className="d-f a-i-c j-c-s-b">
                    {type == 721 ? (
                      <div className="">
                        <div className="merge25 m-q-b-d-n m-t-1 m-b-0_5">
                          {name} (#{tokenID})
                        </div>
                        <span className="m-q-b-d-n">
                          Owned by{" "}
                          {owner == UserWallet ? "You" : sliceAdd(owner)}
                        </span>
                      </div>
                    ) : (
                      <div className="m-q-b-d-n">
                        <div className="merge25 m-q-b-d-n m-t-2">{name}</div>
                        You own {balance_1155} of this
                      </div>
                    )}
                    {/* {creatoraddress == UserWallet &&
                    totalMinted < totalSupple &&
                    type == 721 ? (
                      <div className="">
                        <button
                          className="para_clr  b-1 b-r-10 p-x-2 p-y-0_5 b-c-t m-t-1  "
                          onClick={handleShow_minting}
                        >
                          {" "}
                          Mint
                        </button>
                      </div>
                    ) : (
                      ""
                    )} */}
                  </div>
                  <span>
                    {/* <span>You owned {balance_1155} NFTs</span> */}
                  </span>
                </div>
                {/* <div className="view">
                  <span className="ss">
                    <BsEye className="eye" />
                    <span>5 views</span>
                  </span>{" "}
                  <AiOutlineApartment className="eye" />
                  <span>Art</span>
                </div> */}
                {false ? (
                  <>
                    {isListed ? (
                      <div className="m-b-1">
                        <span className="num">{listedprice} MAAL</span>
                        <span className="num-sub">( ${listedprice} )</span>
                      </div>
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  <div>
                    <div className="best-con">
                      {/* <span className="off">Best Offer</span> */}
                      {isListed ? (
                        <div className="m-b-1">
                          <span className="num">
                            {listedprice} {listingTokenSymbol}
                          </span>
                          {/* <span className="num-sub">( ${listedprice} )</span> */}
                        </div>
                      ) : (
                        ""
                      )}

                      {seller != UserWallet && isListed ? (
                        <button className="bu m-r-1 h-3" onClick={handleShow6}>
                          Buy now
                        </button>
                      ) : (
                        ""
                      )}
                      <br />
                      <br />
                      {/* <button
                        className="bu h-3 yellow_clr w-s-n-w"
                        onClick={() => {
                          if (!isListed) {
                            return;
                          }
                          handleShow5();
                        }}
                      >
                        <BsFillTagFill className="" /> &nbsp; &nbsp;
                        {isListed ? "Make offer" : "Not for Sale"}
                      </button> */}
                    </div>
                  </div>
                )}
                <div className="accordion my-4">
                  <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        <SlGraph className="price-gr" />
                        Price History
                      </Accordion.Header>
                      <Accordion.Body className="p-y-3 t-a-c">
                        No item yet
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
                <div className="accordion my-4">
                  <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        <BsFillTagFill className="price-gr" />
                        Listings
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="listing">
                          <img src={Svgimg} alt="" />
                        </div>
                        <div className="list-no">
                          <div>No listings yet</div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
                <div className="accordion my-4">
                  <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        <TfiMenuAlt className="price-gr" />
                        Offers
                      </Accordion.Header>
                      <Accordion.Body>
                        <Grid container>
                          <Grid item lg={3} md={3} sm={3} xs={3}>
                            <div>
                              <span className="span-pp">Price</span>
                            </div>
                          </Grid>

                          <Grid item lg={3} md={3} sm={3} xs={3}>
                            <div>
                              <span className="span-pp">From</span>
                            </div>
                          </Grid>

                          <Grid item lg={3} md={3} sm={3} xs={3}>
                            <div>
                              <span className="span-pp">Expire Time</span>
                            </div>
                          </Grid>

                          <Grid item lg={3} md={3} sm={3} xs={3}>
                            <div>
                              <span className="span-pp">Action</span>
                            </div>
                          </Grid>
                        </Grid>
                      </Accordion.Body>
                      <Accordion.Body>
                        {alloffers === undefined ? (
                          "Not Found"
                        ) : alloffers.length > 0 ? (
                          alloffers.map((res) => {
                            return (
                              <Grid container>
                                <Grid item lg={3} md={3} sm={3} xs={3}>
                                  <div>
                                    <span>
                                      {res.pricePerNFT / 10 ** 18}{" "}
                                      {listingTokenSymbol}
                                    </span>
                                  </div>
                                </Grid>
                                <Grid item lg={3} md={3} sm={3} xs={3}>
                                  <div>
                                    <span>{sliceAdd(res.offerCreator)}</span>
                                  </div>
                                </Grid>
                                <Grid item lg={3} md={3} sm={3} xs={3}>
                                  <div>
                                    <span>
                                      {Time_data(
                                        Number(res.offerExpireTime) * 1000
                                      )}
                                    </span>
                                  </div>
                                </Grid>
                                {seller == UserWallet ? (
                                  <Grid item lg={3} md={3} sm={3} xs={3}>
                                    <div>
                                      <button
                                        onClick={() =>
                                          accept_offer(
                                            convertToLowercase(
                                              res.offerCreator
                                            ),
                                            res.pricePerNFT,
                                            res.offerId
                                          )
                                        }
                                        className="b-c-t b-1 m-b-1"
                                      >
                                        Accept
                                      </button>
                                    </div>
                                  </Grid>
                                ) : seller === UserWallet ? (
                                  <Grid item lg={3} md={3} sm={3} xs={3}>
                                    <div>
                                      <button
                                        onClick={() => {
                                          handleShow7();
                                          setOfferID(res.offerId);
                                        }}
                                        className="b-c-t b-1 m-b-1"
                                      >
                                        Update
                                      </button>
                                    </div>
                                  </Grid>
                                ) : (
                                  ""
                                )}
                              </Grid>
                            );
                          })
                        ) : (
                          <div className="p-y-3 t-a-c">No data to show...</div>
                        )}
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
        <div className="container-fluid my-5">
          <div>
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  <RiArrowUpDownFill className="price-gr" />
                  Item Activity
                </Accordion.Header>
                <Accordion.Body>
                  <div>
                    {" "}
                    <InputGroup className="mb-3 filterinput ">
                      <Form.Control
                        aria-label="Text input with dropdown button"
                        placeholder="Filter"
                      />

                      <DropdownButton
                        variant="outline-secondary"
                        title={<IoIosArrowDown />}
                        id="input-group-dropdown-2"
                        align="end"
                      >
                        <Dropdown.Item href="#">Sales</Dropdown.Item>{" "}
                        <Dropdown.Divider />
                        <Dropdown.Item href="#">Listings</Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item href="#">Offers</Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item href="#">Transfers</Dropdown.Item>
                      </DropdownButton>
                    </InputGroup>
                  </div>
                  <Accordion.Body>
                    <Grid container>
                      <Grid item xs className="t-a-c">
                        <div>
                          <span className="span-pp">Event</span>
                        </div>
                      </Grid>
                      <Grid item xs className="t-a-c">
                        <div>
                          <span className="span-pp">Price</span>
                        </div>
                      </Grid>
                      <Grid item xs className="t-a-c">
                        <div>
                          <span className="span-pp">From</span>
                        </div>
                      </Grid>
                      <Grid item xs className="t-a-c">
                        <div>
                          <span className="span-pp">To</span>
                        </div>
                      </Grid>
                      <Grid item xs className="t-a-c">
                        <div>
                          <span className="span-pp">Date</span>
                        </div>
                      </Grid>
                    </Grid>
                  </Accordion.Body>
                  <Accordion.Body>
                    {nfttransaction ? (
                      nfttransaction.length === 0 ? (
                        <div className="p-y-3 t-a-c">No data to show...</div>
                      ) : (
                        nfttransaction.map((res) => {
                          return (
                            <Grid container>
                              <Grid item xs className="t-a-c">
                                <div>
                                  <span className="price-amu">
                                    <BiTransfer className="trans" />
                                    {res.event}
                                  </span>
                                </div>
                              </Grid>
                              <Grid item xs className="t-a-c">
                                <div>
                                  <span>{res.value}</span>
                                </div>
                              </Grid>
                              <Grid item xs className="t-a-c">
                                <div>
                                  <span className="from-da">
                                    {sliceAddresss(res.from)}
                                  </span>
                                </div>
                              </Grid>
                              <Grid item xs className="t-a-c">
                                <div>
                                  <span className="from-da">
                                    {sliceAddresss(res.to)}
                                  </span>
                                </div>
                              </Grid>
                              <Grid item xs className="t-a-c">
                                <div>
                                  <span className="from-te">
                                    {Time_ago(res.time)}{" "}
                                    <span className="grshare"></span>
                                  </span>
                                </div>
                              </Grid>
                            </Grid>
                          );
                        })
                      )
                    ) : (
                      ""
                    )}
                  </Accordion.Body>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
          <div className="my-5">
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  <BsFillCollectionFill className="price-gr" />
                  More From This Collection
                </Accordion.Header>
                <Accordion.Body>
                  <div className="container-fluid">
                    <div className="row mb-5">
                      {nftofsamecollection === undefined ? (
                        <div className="p-y-3 t-a-c">Not data to show...</div>
                      ) : nftofsamecollection.length > 0 ? (
                        nftofsamecollection.map((res) => {
                          return (
                            <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                              <Card className="profile-card">
                                <Card.Img variant="top" src={res.imageURL} />
                                <Card.Body>
                                  <div className="d-f j-c-s-b a-i-c">
                                    <div className="">
                                      <Card.Title className="card-head">
                                        {res.name}
                                      </Card.Title>
                                      {res.pricing == 0 ? (
                                        "Not listed"
                                      ) : (
                                        <Card.Text>
                                          {res.pricing / 10 ** 18}
                                        </Card.Text>
                                      )}
                                      <br />
                                      <span className="last-da">
                                        Last Sale: 0.088 ETH
                                      </span>
                                    </div>
                                  </div>
                                </Card.Body>
                                <div className="add-cart-section ">
                                  <Link to={`/assets/${res._id}`}>
                                    <div className="add-cart-section ">
                                      <span className="add-sec">View</span>
                                    </div>
                                  </Link>
                                  <span className="add-sec buy">
                                    <AiFillThunderbolt className="buy-thunder" />
                                    Buy now
                                  </span>
                                </div>
                              </Card>
                            </div>
                          );
                        })
                      ) : (
                        <div className="p-y-3 t-a-c">Not found</div>
                      )}
                    </div>
                  </div>
                </Accordion.Body>

                {/* <Accordion.Body>
                  <div className="view-btn1">
                    <button className="collection-btn">View collection</button>
                  </div>
                </Accordion.Body> */}
              </Accordion.Item>
            </Accordion>
          </div>
        </div>

        {/* ------------Sell------------- */}

        <Modal
          show={show4}
          onHide={handleClose4}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header className="w-100">
            <div className="d-f j-c-s-b a-i-c w-100">
              <div className="">
                <Modal.Title>Sell NFTs</Modal.Title>
              </div>
              <div className="">
                <RxCross1 className="c-p" onClick={handleClose4} />
              </div>
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="pa-3 t-a-c">
              <div className="input-field m-y-1 w-100">
                {/* <input
                  value={tokenID}
                  type="text"
                  disabled
                  className="input-field-sell w-100 b-n"
                /> */}
              </div>

              <div className="input-field m-y-1 w-100 p-b-1">
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Select Token
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    onChange={(e) => setTokenForSale(e.target.value)}
                    label="Select Token"
                  >
                    {TOKENS.map((res) => {
                      return (
                        <MenuItem value={res.address}>{res.name}</MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </div>
              <div className="input-field m-y-1 w-100">
                <div className="d-f a-i-c b-1 b-r-10">
                  <div className="w-100  ">
                    <input
                      onChange={(e) => handlePrice(e.target.value)}
                      type="text"
                      className=" w-100 pa-0_5 b-n"
                      id="input-submit-btn"
                      placeholder="amount"
                    />
                  </div>

                  {/* <div className=" w-s-n-w pa-0_5">MAAL</div> */}
                </div>
                <div className="m-y-1">
                  {type === 721 ? (
                    ""
                  ) : (
                    <input
                      type="number"
                      onChange={(e) => setQuantityToSale(e.target.value)}
                      placeholder="quantity"
                      className=" w-100 pa-0_5 b-1 b-r-10"
                    />
                  )}
                </div>
                <div className="m-y-1">
                  {/* <DatePicker showTime onChange={onChange} onOk={onOk} /> */}
                  <input
                    type="datetime-local"
                    onChange={(e) =>
                      setExpireTime(
                        (new Date(e.target.value).getTime() / 1000).toFixed(0)
                      )
                    }
                    className=" w-100 pa-0_5 b-1 b-r-10"
                  />
                </div>
              </div>
              <span onClick={handleClose4}>
                <button className="sell-btn m-b-2" onClick={() => sellNFT()}>
                  Submit
                </button>
              </span>
            </div>
          </Modal.Body>
        </Modal>

        {/* --------------Make Offer----------------- */}

        <Modal
          show={show5}
          onHide={handleClose5}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header className="w-100">
            <div className="d-f j-c-s-b a-i-c w-100">
              <div className="">
                <Modal.Title>
                  <div className="">Make Offer</div>
                </Modal.Title>
              </div>
              <div className="">
                <RxCross1 className="c-p" onClick={handleClose5} />
              </div>
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="pa-3 t-a-c">
              <div className="input-field m-y-1 w-100">
                <div className="d-f a-i-c m-y-2 b-1 b-r-10">
                  <div className="w-100  ">
                    <input
                      placeholder="amount per nft"
                      onChange={(e) => setOfferamount(e.target.value)}
                      type="text"
                      className=" w-100 pa-0_5 b-n"
                      id="input-submit-btn"
                    />
                  </div>
                  <div className=" w-s-n-w pa-0_5">
                    {Number(listingTokenBalance).toFixed(3)}{" "}
                    {listingTokenSymbol}
                  </div>
                </div>

                <div className="m-y-2">
                  <input
                    type="text"
                    placeholder={`Max quantity to puchase is ${quantityonsale}`}
                    onChange={(e) => {
                      if (Number(e.target.value) > quantityonsale) {
                        warning(`Max purchase is ${quantityonsale}`);
                        return;
                      }
                      setQuantityToPurchase(e.target.value);
                    }}
                    className="w-100 b-1 b-r-10 pa-0_5"
                  />
                </div>
                <div className="m-y-2">
                  <input
                    type="datetime-local"
                    onChange={(e) =>
                      setOfferExpire(
                        (new Date(e.target.value).getTime() / 1000).toFixed(0)
                      )
                    }
                    className="w-100 b-1 b-r-10 pa-0_5"
                  />
                </div>
              </div>

              <span onClick={handleClose5}>
                <button
                  className="primary-btn m-b-2"
                  onClick={() => make_Offerr()}
                >
                  Submit
                </button>
              </span>
            </div>
          </Modal.Body>
        </Modal>

        {/* --------------Minting----------------- */}

        <Modal
          show={show_minting}
          onHide={handleClose_minting}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header className="w-100">
            <div className="d-f j-c-s-b a-i-c w-100">
              <div className="">
                <Modal.Title>
                  <div className="">Minting</div>
                </Modal.Title>
              </div>
              <div className="">
                <RxCross1 className="c-p" onClick={handleClose_minting} />
              </div>
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="pa-3 t-a-c">
              <div className="input-field m-y-1 w-100">
                <div className="m-b-2">
                  <input
                    type="text"
                    onChange={(e) =>
                      setReceiverAddresss(convertToLowercase(e.target.value))
                    }
                    placeholder="enter receiver address"
                    className="w-100 b-1 b-r-10 pa-0_5"
                  />
                </div>
              </div>

              <span onClick={handleClose5}>
                {/* <button
                  className="primary-btn m-b-2"
                  onClick={() => mintAgain(nft)}
                >
                  Mint
                </button> */}
              </span>
            </div>
          </Modal.Body>
        </Modal>

        {/* ---------------Buy nft------------- */}
        <Modal
          show={show6}
          onHide={handleClose6}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header className="w-100">
            <div className="d-f j-c-s-b a-i-c w-100">
              <div className="">
                <Modal.Title>
                  <div className="">Buy NFT</div>
                </Modal.Title>
              </div>
              <div className="">
                <RxCross1 className="c-p" onClick={handleClose6} />
              </div>
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="pa-3 t-a-c">
              <div className="input-field m-y-1 w-100">
                <div className="d-f a-i-c m-y-2 b-1 b-r-10">
                  <div className="w-100  ">
                    <input
                      placeholder={`quantity on sale are ${quantityonsale}`}
                      onChange={(e) =>
                        setQuantityToPurchaseDirect(e.target.value)
                      }
                      type="text"
                      className=" w-100 pa-0_5 b-n"
                      id="input-submit-btn"
                    />
                  </div>
                </div>
              </div>

              <span onClick={handleClose5}>
                <button className="primary-btn m-b-2" onClick={() => buy_nft()}>
                  Submit
                </button>
              </span>
            </div>
          </Modal.Body>
        </Modal>

        {/* ---------------Update Offer------------- */}
        <Modal
          show={show7}
          onHide={handleShow7}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header className="w-100">
            <div className="d-f j-c-s-b a-i-c w-100">
              <div className="">
                <Modal.Title>
                  <div className="">Update Offer</div>
                </Modal.Title>
              </div>
              <div className="">
                <RxCross1 className="c-p" onClick={handleShow7} />
              </div>
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="pa-3 t-a-c">
              <div className="input-field m-y-1 w-100">
                <div className="d-f a-i-c m-y-2 b-1 b-r-10">
                  <div className="w-100">
                    <input
                      placeholder="amount per nft"
                      onChange={(e) => setNewPrice(e.target.value)}
                      type="text"
                      className=" w-100 pa-0_5 b-n"
                      id="input-submit-btn"
                    />
                  </div>
                  <div className=" w-s-n-w pa-0_5">
                    {Number(listingTokenBalance).toFixed(3)}{" "}
                    {listingTokenSymbol}
                  </div>
                </div>

                <div className="m-y-2">
                  <input
                    type="datetime-local"
                    onChange={(e) => setNewTime(e.target.value)}
                    className="w-100 b-1 b-r-10 pa-0_5"
                  />
                </div>
              </div>

              <span onClick={handleShow7}>
                <button
                  className="primary-btn m-b-2"
                  onClick={() => updateOffer(newprice, newTime)}
                >
                  Update Offer
                </button>
              </span>
              <br />
              <span onClick={handleShow7}>
                <button
                  className="primary-btn m-b-2"
                  onClick={() => CancalOffer(offerID)}
                >
                  Cancel Offer
                </button>
              </span>
            </div>
          </Modal.Body>
        </Modal>
      </Box>

      <Footer />
      <Toaster />
      {/* {open ? <Loader percent={percent} /> : ""} */}

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}
