import React from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import "./Progressbar.css";

function Progressbar({ percent }) {
  return (
    <>
      {/* ------progress-bar--------- */}

      <div className="progress-bar-create">
        <div className="progress-bar-event">
         {percent === 100 ? "Transaction is success" : " Transaction is in progress...."}
          <br />
          <br />
          <span className="progress-bar-text">
            <ProgressBar id="create-event-page-progress" now={percent} />
            <div className="circle-box">
              <span className="circle1">1</span>
              <span className="circle2">2</span>
              <span className="circle3">3</span>
            </div>
          </span>
        </div>
      </div>
    </>
  );
}

export default Progressbar;
