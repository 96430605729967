import React, { Component, useEffect, useState } from "react";
import Slider from "react-slick";
import "./headerslider.css";
import Image1 from "../Images/mainslider1.png";
import Image2 from "../Images/mainslider2.png";
import Image3 from "../Images/mainslider3.png";
import Image4 from "../Images/mainslider4.png";
import Image5 from "../Images/mainslider1.png";
import Image6 from "../Images/mainslider2.png";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import axios from "axios";
import { FallingLines } from "react-loader-spinner";
import { Link } from "react-router-dom";
import logo_nft from "../Images/logo_nft.jpg";
import { get_all_nft_contract_ } from "../Web3/Contract";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

const CustomPrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} custom-arrow-prev`}
      style={{ ...style, left: "-30px" }}
      onClick={onClick}
    >
      {/* Your custom previous arrow content */}
      <div className="custom-arrow-icon p-r-2_5">
        <IoIosArrowBack className="f-w-600" />
      </div>
    </div>
  );
};

// Custom next arrow component
const CustomNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} custom-arrow-next`}
      style={{ ...style, right: "-30px" }}
      onClick={onClick}
    >
      {/* Your custom next arrow content */}
      <div className="custom-arrow-icon  p-r-2">
        <IoIosArrowForward className="f-w-600" />
      </div>
    </div>
  );
};

const Responsive = ({ UserWallet, URL }) => {
  const [isHovered, setIsHovered] = useState(false);

  var settings = {
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    dots: false,
    arrows: isHovered,
    infinite: false,
    speed: 500,
    autoplay: true, // Enable autoplay
    autoplaySpeed: 2000, // Set autoplay interval (in milliseconds)
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
          arrows: isHovered,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          arrows: isHovered,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: isHovered,
        },
      },
    ],
  };
  // const [nfts, setNFTs] = useState([]);

  // useEffect(() => {
  //   const init = async () => {
  //     const nftsarray = [];
  //     axios
  //       .get(`${URL}/allnewnfts`)
  //       .then(async (response) => {
  //         // setNFTs(res.data);
  //         for (let i = 0; i < response.data.length; i++) {
  //           // console.log(response.data[i]);
  //           if (response.data[i].isCustom) {
  //             const contractValidator = await get_all_nft_contract_(
  //               response.data[i].NFTContract
  //             );
  //             const uri = await contractValidator.methods.baseURI().call();
  //             // const nftname = await contractValidator.methods.name().call();
  //             const Datafromserver = await axios
  //               .post(`${URL}/callcorsapi`, {
  //                 uri: `${uri}${response.data[i].tokenID}.json`,
  //               })
  //               .then((res) => {
  //                 return res.data;
  //               });
  //             // response.data[i].name = nftname;
  //             // response.data[i].data = Datafromserver;
  //             // response.data[i].contractanimation = Datafromserver.animation_url;
  //             response.data[i].imageURL = Datafromserver.image;
  //             nftsarray.push(response.data[i]);
  //           } else {
  //             nftsarray.push(response.data[i]);
  //           }
  //         }

  //         setNFTs(nftsarray);
  //       })
  //       .catch(console.error);
  //   };
  //   init();
  // }, []);

  const [collection, setCollection] = useState([]);

  useEffect(() => {
    const init = async () => {
      axios
        .get(`${URL}/myallcollections`)
        .then((res) => {
          setCollection(res.data);
        })
        .catch(console.error);
    };
    init();
  }, []);

  return (
    <div
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      className=" "
    >
      <Slider {...settings}>
        {collection
          ? collection.map((res) => {
              return (
                <>
                  <div className="pa-0_5 ps-r">
                    <Link
                      to={`collection/${res.name
                        .toLowerCase()
                        .replace(/\s+/g, "-")}/${res.Collection_id}`}
                      className=""
                    >
                      {res.imageURL ? (
                        <>
                          <img
                            src={res.featureImage}
                            alt=""
                            className="slider1 b_c_w"
                          />
                          <div className="ps-a">MAAL</div>
                        </>
                      ) : (
                        <>
                          <div className="ps-r">
                            <img
                              src={res.featureImage}
                              alt=""
                              className="slider1 m-q-h-25 b_c_w"
                            />
                            <div className="ps-a c-w b_0 f-w-600 p-y-1 w-100 p-l-1_5 b-r-btm l_0 bg_clr_blacks">
                              {res.name}
                            </div>
                          </div>
                        </>
                      )}
                    </Link>
                  </div>
                </>
              );
            })
          : ""}
      </Slider>

      {collection.length > 0 ? (
        " "
      ) : (
        <div className="row">
          <div className="col-12 col-sm-6 col-md-3 col-lg-3 m-q-b-d-n">
            {" "}
            <div className="pa-0_5 b-r-20">
              <Skeleton
                height={200}
                // width={300}
                baseColor="#E4E9EB"
                highlightColor="#fff"
                className="br-12"
              />
            </div>
          </div>

          <div className="col-12 col-sm-6 col-md-3 col-lg-3 m-q-b-d-n">
            {" "}
            <div className="pa-0_5 b-r-20">
              <Skeleton
                height={200}
                // width={300}
                baseColor="#E4E9EB"
                highlightColor="#fff"
                className="br-12"
              />
            </div>
          </div>
          <div className="col-12 col-sm-6 col-md-3 col-lg-3 m-q-b-d-n">
            {" "}
            <div className="pa-0_5 b-r-20">
              <Skeleton
                height={200}
                // width={300}
                baseColor="#E4E9EB"
                highlightColor="#fff"
                className="br-12"
              />
            </div>
          </div>
          <div className="col-12 col-sm-6 col-md-3 col-lg-3">
            {" "}
            <div className="pa-0_5 b-r-20">
              <Skeleton
                height={200}
                // width={300}
                baseColor="#E4E9EB"
                highlightColor="#fff"
                className="br-12"
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Responsive;
