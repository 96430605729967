import React from "react";
import "./Tab.css";
import Dropdown from "react-bootstrap/Dropdown";
import Hometable from "./Hometable";
import { Box } from "@mui/material";
import Table from "./Table";

function Tab({ UserWallet, URL }) {
  return (
    <div className="p-x-2">
      <Hometable UserWallet={UserWallet} URL={URL} />
    </div>
  );
}

export default Tab;
