import axios from "axios";
import { ethers } from "ethers";
import Web3 from "web3";


//testnet https://node1.maalscan.io
//mainnet https://node1-mainnet.maalscan.io
let provide = "https://node1.maalscan.io"

const message =(wallet) => `Welcome to PanSea!

Click to sign in and accept the PanSea Terms of Service (https://pansea.io/terms) and Privacy Policy (https://pansea.io/privacy).

This request will not trigger a blockchain transaction or cost any gas fees.

Your authentication status will reset after 24 hours.
`

  export const signMessage = async () => {
    try {
      await window.ethereum.send("eth_requestAccounts");
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      provide = window.ethereum
      const user = await axios.post(`${window.apiURL}/getuser`, {
        wallet: await getAddress()
      }).then((res)=>{
        return res.data
      })
      // console.log(user)
      if(user.length <= 0){
        const signature = await signer.signMessage(message(signer));
        const address = await signer.getAddress();
        const isVerify = await verifyMessage(message(signer), address, signature)
        if(isVerify){
          window.localStorage.setItem("signature", `${signature}`)
          await axios.post(`${window.apiURL}/makeuser`, {
            Creator: await getAddress()
          }).then((res)=>{
            return res.data[0].Creator
          })
        }
        return isVerify
      }
      let signatures = window.localStorage.getItem("signature")
      if(signatures){
        const storedaddress = await signer.getAddress();
        const isVerify = await verifyMessage(message(signer), storedaddress, signatures)
        if(isVerify){
            return isVerify
        }
        else{
            const signature = await signer.signMessage(message(signer));
            const address = await signer.getAddress();
            const isVerify = await verifyMessage(message(signer), address, signature)
            if(isVerify){
              window.localStorage.setItem("signature", `${signature}`)
            }
            return isVerify
        }
        }
        const signature = await signer.signMessage(message(signer));
        const address = await signer.getAddress();
        const isVerify = await verifyMessage(message(signer), address, signature)
        if(isVerify){
            window.localStorage.setItem("signature", `${signature}`)
        }
        return isVerify
    } catch (err) {
      console.log(err)
    }
  };


  export  const verifyMessage = async (message, address, signature ) => {
    try {
      const signerAddr = await ethers.utils.verifyMessage(message, signature);
      if (signerAddr === address) {
        return true;
      }
      else{
        return false
      }
    } catch (err) {
      return false;
    }
  };



  export const convertToLowercase =(str)=> {
    let result = '';
    for (let i = 0; i < str.length; i++) {
      let charCode = str.charCodeAt(i);
      if (charCode >= 65 && charCode <= 90) {
        result += String.fromCharCode(charCode + 32);
      } else {
        result += str.charAt(i);
      }
    }
    return result;
  }


  export  const getAddress = async()=>{
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      provide = window.ethereum
      const address = await provider.send("eth_requestAccounts", []);
      const lower = convertToLowercase(address[0])
      return lower;
    } catch (error) {
      console.log(error)
    }
  }

  export const getProvider =async()=>{
    return new Web3(provide)
    //testnet https://node1.maalscan.io
    //mainnet https://node1-mainnet.maalscan.io
  }

  export const GetChainId = async () => {
    try {
      const web3  = await getProvider();
      const id = await web3.eth.getChainId()
      return id
    } catch (error) {
  
    }
  }

  export const userBalanceOfChain = async (userAddress) => {
    try {
       const data = await axios.post('https://node1.maalscan.io', {
        "jsonrpc": "2.0",
        "method": "eth_getBalance",
        "params": [userAddress, "latest"],
        "id": 880
      }).then((res) => {
        const bal = (parseInt(res.data.result, 16)/10**18)
        return Number(bal)
      }).catch((error) => {
        console.log(error)
      })
      return data
    } catch (error) {
      console.log(error)
    }
  }

export const utils_towei = async(value)=>{
  const pro = await getProvider();
  const num = await pro.utils.toWei(Number(value).toString(),"ether")
  return num
}

export const utils_fromowei = async(value)=>{
  const pro = await getProvider();
  const num = await pro.utils.fromWei(value, "ether")
  return num
}