import React, { useEffect, useState } from "react";
import Navbarnft from "./../Navbars/Navbarnft";
import Drop_swiper_slider from "./Drop_swiper_slider";
import "./Drop.css";
import {
  BsDiscord,
  BsGlobe,
  BsThreeDots,
} from "react-icons/bs";
import {
  AiFillInstagram,
  AiOutlineTwitter,
} from "react-icons/ai";
import { BiUpload } from "react-icons/bi";
import { ProgressBar } from "react-bootstrap";
import { useParams } from "react-router-dom";
import axios from "axios";
import {
  get_Airdrop_NFT_contract,
  get_Airdrop_NFT_contract_by_address,
  get_Minting_contract_721,
} from "../../Web3/Contract";
import toast, { Toaster } from "react-hot-toast";
import {
  convertToLowercase,
  utils_towei,
} from "../../Web3/web3_api";
import { Backdrop, CircularProgress } from "@mui/material";

const notify = (msg) => toast.success(msg);
const warning = (msg) => toast.error(msg);

function Drop({ UserWallet, signMessage, logout, URL }) {
  const { name, id } = useParams();

  const [contractaddress, setContract] = useState();
  const [descriptions, setDescription] = useState();
  const [bannerImage, setBannerImage] = useState();
  const [logo, setLogo] = useState();
  const [images, setimages] = useState([]);
  const [creatorname, setCreatorName] = useState();
  const [open, setOpen] = useState(false);
  const [nftTotalSupply, setNftTotalSupply] = useState(0);
  const [nftMaxSupply, setNftMaxSupply] = useState(0);
  const [collectionID, setCollectionID] = useState("");
  const [creator, setCreator] = useState();
  const [maxPerWallet, setMaxPerWallet] = useState(0);
  const [mintAmount, setMintAmount] = useState(0);
  const [price, setPrice] = useState();
  const [owner, setOwner] = useState();
  const [isrevealed, setIsRevealed] = useState(false);
  const [whitelisted, setWhiteListed] = useState(true);
  const [urilink, setURILink] = useState("");
  const [publicMinting, setPublicMinting] = useState();

  /// for API calles ////
  useEffect(() => {
    const init = async () => {
      axios
        .post(`${URL}/getairdropdetails`, {
          id: id,
        })
        .then((res) => {
          setContract(res.data.NFTContract);
          setDescription(res.data.description);
          setBannerImage(res.data.BannerImage);
          setLogo(res.data.FeturedImage);
          setPrice(res.data.mintPrice);
          setMaxPerWallet(res.data.maxPerWallet);
          setimages([
            res.data.imageOne,
            res.data.imageTwo,
            res.data.imageThree,
            res.data.imageFour,
          ]);
          setCollectionID(res.data.collectionID);
          setCreator(res.data.Creator);
          axios
            .post(`${URL}/getuser`, {
              wallet: res.data.Creator,
            })
            .then((responce) => {
              setCreatorName(responce.data[0].name);
            });
        })
        .catch((error) => {
          console.log(error);
        });
    };
    init();
  }, [UserWallet, open]);

  useEffect(() => {
    const init = async () => {
      const contract =
        await get_Airdrop_NFT_contract_by_address(
          contractaddress
        );
      const totalSupply = await contract.methods
        .totalSupply()
        .call();
      const maxSupply = await contract.methods
        .maxSupply()
        .call();

      const ownerOfContract = await contract.methods
        .owner()
        .call();
      setOwner(convertToLowercase(ownerOfContract));
      const whiteListed = await contract.methods
        .isWhitelisted()
        .call();
      setWhiteListed(whiteListed);
      const isReveaded = await contract.methods
        .getBaseURI()
        .call();
      if (isReveaded == "" || isReveaded == undefined) {
        setIsRevealed(false);
      } else {
        setIsRevealed(true);
      }

      setNftTotalSupply(totalSupply);
      setNftMaxSupply(maxSupply);
    };
    init();
  }, [UserWallet, contractaddress, open]);

  const MintAirDrop = async () => {
    try {
      setOpen(true);
      const id = new Date().getTime();
      const a = await utils_towei(price * mintAmount);
      const contract =
        await get_Airdrop_NFT_contract_by_address(
          contractaddress
        );
      const data = await contract.methods
        .mint(mintAmount)
        .send({ from: UserWallet, value: a });
      const tokenIDs = await contract.methods
        .getNFTsOwned(UserWallet)
        .call();
      const lastElements = tokenIDs.slice(
        -Number(mintAmount)
      );
      console.log(tokenIDs, lastElements);
      if (data.status) {
        await axios
          .post(`${URL}/makeingmultiplenfts`, {
            Creator: creator.toLowerCase(),
            Owner: UserWallet.toLowerCase(),
            last_update: new Date().getTime(),
            collectionID: collectionID,
            supply: nftMaxSupply,
            isNFTMinted: true,
            starting: lastElements,
            airDropNFT: true,
            chain: 786,
            mintAmount: mintAmount,
            NFTContract: contractaddress,
            nftType: 721,
            last_sold_price: 0,
            tokenID: id,
          })
          .then((res) => {
            console.log(res.data);
            setOpen(false);
            notify("NFT created successfully");
          })
          .catch((error) => {
            setOpen(false);
            console.log(error);
          });
      }
    } catch (error) {
      setOpen(false);
      console.log(error);
    }
  };

  const updateURI = async (link) => {
    try {
      setOpen(true);
      const contract =
        await get_Airdrop_NFT_contract_by_address(
          contractaddress
        );
      const data = await contract.methods
        .revealNFTs(link)
        .send({ from: UserWallet });
      if (data.status) {
        setOpen(false);
        notify("Updated URI");
      }
    } catch (error) {
      console.log(error);
      setOpen(false);
    }
  };

  // const makeWhiteList = async(wallets)=>{
  //   try {
  //     const contract = await get_Airdrop_NFT_contract_by_address(
  //       contractaddress
  //     );
  //     const data = await contract.methods.builkWhitelistWallets(link).send({from:UserWallet})
  //     if(data.status){
  //       setOpen(false);
  //       notify("Updated URI");
  //     }
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }

  const openMintingForPublic = async () => {
    try {
      setOpen(true);
      const contract =
        await get_Airdrop_NFT_contract_by_address(
          contractaddress
        );
      const data = await contract.methods
        .openPublicMinting()
        .send({ from: UserWallet });
      if (data.status) {
        setOpen(false);
        notify("Success");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <div className="bg_slider">
        <Navbarnft
          UserWallet={UserWallet}
          signMessage={signMessage}
          logout={logout}
          URL={URL}
        />
      </div>

      {/* -----------first-section------------ */}
      <div className="sc-57ceaf99-0 kHrhvD ">
        <div className="sc-57ceaf99-0 sc-abc3a873-2 kHrhvD cDfrIB pa-2">
          <div className="media-greaterThanOrEqual-md">
            <span className="image_bgg">
              {" "}
              <img
                alt=""
                sizes="100vw"
                srcset=""
                src={bannerImage}
                decoding="async"
                data-nimg="fill"
                className="image_bgg_inside"
              />
            </span>
          </div>
          <div className="sc-57ceaf99-0 sc-630fc9ab-0 sc-ecfc7326-0 sc-abc3a873-3 gkoOuE bNkKFC buAnIP ixSLhZ">
            <div className="sc-57ceaf99-0 sc-630fc9ab-0 sc-99655001-0 sc-abc3a873-4 kHrhvD bNkKFC dsowZP PpLlC">
              <div className="">
                <span className="">
                  {" "}
                  <img
                    sizes="100vw"
                    src={logo}
                    decoding="async"
                    data-nimg="fill"
                    className="w-7 d-i-b h-7 b-5px b-r-10"
                  />
                </span>
              </div>
              <div className="sc-57ceaf99-0 sc-630fc9ab-0 sc-99655001-0 doJkTB bNkKFC dsowZP">
                <h1 className="sc-abc3a873-9 gComsc">
                  <span className="sc-57ceaf99-0 sc-f672aa35-0 sc-99b4f548-7 kgbdeo hkMvEZ ibjlmS sc-abc3a873-5 dxaCRO f-s-3 f-w-600">
                    {name}
                    <span>
                      <svg
                        aria-label="verified-icon"
                        className="sc-9c65691d-0 ciZrIX sc-abc3a873-8 hkHXpY w-2 h-2 f-s-2 f-w-600"
                        fill="none"
                        viewBox="0 0 43 46"
                      >
                        <path
                          clip-rule="evenodd"
                          d="M23.6741 2.0787C22.3933 0.494275 19.9777 0.494276 18.6969 2.0787L16.932 4.26193C16.1363 5.24636 14.835 5.66918 13.6126 5.3405L10.9015 4.61157C8.93402 4.08257 6.97973 5.50245 6.87485 7.5371L6.73034 10.3407C6.66518 11.6049 5.86095 12.7118 4.67879 13.1644L2.05704 14.1682C0.154385 14.8967 -0.592088 17.1941 0.519004 18.9018L2.05002 21.255C2.74036 22.316 2.74036 23.6842 2.05002 24.7453L0.519003 27.0984C-0.592088 28.8061 0.154384 31.1035 2.05704 31.832L4.67879 32.8358C5.86095 33.2884 6.66518 34.3954 6.73034 35.6595L6.87485 38.4631C6.97973 40.4978 8.93402 41.9177 10.9015 41.3887L13.6126 40.6597C14.835 40.3311 16.1363 40.7539 16.932 41.7383L18.6969 43.9215C19.9777 45.506 22.3933 45.506 23.6741 43.9215L25.4389 41.7383C26.2347 40.7539 27.536 40.3311 28.7584 40.6597L31.4695 41.3887C33.4369 41.9177 35.3912 40.4978 35.4961 38.4631L35.6406 35.6595C35.7058 34.3953 36.51 33.2884 37.6922 32.8358L40.3139 31.832C42.2166 31.1035 42.963 28.8061 41.8519 27.0984L40.3209 24.7453C39.6306 23.6842 39.6306 22.316 40.3209 21.255L41.8519 18.9018C42.963 17.1941 42.2166 14.8967 40.3139 14.1682L37.6922 13.1644C36.51 12.7118 35.7058 11.6049 35.6406 10.3407L35.4961 7.53709C35.3912 5.50244 33.4369 4.08257 31.4695 4.61157L28.7584 5.3405C27.536 5.66918 26.2347 5.24636 25.4389 4.26193L23.6741 2.0787ZM32.8789 17.6449L29.9646 14.7307L18.6075 26.0878L14.1075 21.5878L11.1933 24.5021L18.6075 31.9163L32.8789 17.6449Z"
                          fill="white"
                          fill-rule="evenodd"
                        ></path>
                      </svg>
                    </span>
                  </span>
                </h1>
                <div className="sc-57ceaf99-0 sc-630fc9ab-0 itfOsM bNkKFC">
                  <span className="sc-57ceaf99-0 sc-f672aa35-2 sc-abc3a873-6 frrCDQ clBPfX kBCuOk">
                    By&nbsp;
                    <div
                      className="sc-57ceaf99-0 sc-7bfbf895-1 RYcZ fMWhSV AccountLink--ellipsis-overflow"
                      data-testid="AccountLink"
                      font-weight="inherit"
                      height="24px"
                    >
                      <span
                        className="sc-57ceaf99-0 kHrhvD"
                        aria-expanded="false"
                      >
                        <a
                          className="sc-1f719d57-0 bSLFdB sc-57ceaf99-0 crebQt AccountLink--ellipsis-overflow"
                          font-weight="inherit"
                          href="/ZTX_Official"
                        >
                          <div
                            className="sc-48082a-0 axQXd c-w d-f a-i-c"
                            tabindex="-1"
                          >
                            <div className="">
                              {creatorname} &nbsp;{" "}
                              <svg
                                aria-label="verified-icon"
                                className="sc-9c65691d-0 hDXJCP sc-abc3a873-7 gFYqjx f-w"
                                fill="none"
                                viewBox="0 0 43 46"
                              >
                                <path
                                  clip-rule="evenodd"
                                  d="M23.6741 2.0787C22.3933 0.494275 19.9777 0.494276 18.6969 2.0787L16.932 4.26193C16.1363 5.24636 14.835 5.66918 13.6126 5.3405L10.9015 4.61157C8.93402 4.08257 6.97973 5.50245 6.87485 7.5371L6.73034 10.3407C6.66518 11.6049 5.86095 12.7118 4.67879 13.1644L2.05704 14.1682C0.154385 14.8967 -0.592088 17.1941 0.519004 18.9018L2.05002 21.255C2.74036 22.316 2.74036 23.6842 2.05002 24.7453L0.519003 27.0984C-0.592088 28.8061 0.154384 31.1035 2.05704 31.832L4.67879 32.8358C5.86095 33.2884 6.66518 34.3954 6.73034 35.6595L6.87485 38.4631C6.97973 40.4978 8.93402 41.9177 10.9015 41.3887L13.6126 40.6597C14.835 40.3311 16.1363 40.7539 16.932 41.7383L18.6969 43.9215C19.9777 45.506 22.3933 45.506 23.6741 43.9215L25.4389 41.7383C26.2347 40.7539 27.536 40.3311 28.7584 40.6597L31.4695 41.3887C33.4369 41.9177 35.3912 40.4978 35.4961 38.4631L35.6406 35.6595C35.7058 34.3953 36.51 33.2884 37.6922 32.8358L40.3139 31.832C42.2166 31.1035 42.963 28.8061 41.8519 27.0984L40.3209 24.7453C39.6306 23.6842 39.6306 22.316 40.3209 21.255L41.8519 18.9018C42.963 17.1941 42.2166 14.8967 40.3139 14.1682L37.6922 13.1644C36.51 12.7118 35.7058 11.6049 35.6406 10.3407L35.4961 7.53709C35.3912 5.50244 33.4369 4.08257 31.4695 4.61157L28.7584 5.3405C27.536 5.66918 26.2347 5.24636 25.4389 4.26193L23.6741 2.0787ZM32.8789 17.6449L29.9646 14.7307L18.6075 26.0878L14.1075 21.5878L11.1933 24.5021L18.6075 31.9163L32.8789 17.6449Z"
                                  fill="white"
                                  fill-rule="evenodd"
                                ></path>
                              </svg>{" "}
                            </div>

                            {/* <img src={ticked} className=""  /> */}
                          </div>
                        </a>
                      </span>
                    </div>
                  </span>
                  <div className="sc-57ceaf99-0 sc-630fc9ab-0 sc-99655001-0 sc-4422a702-0 sc-dada3b88-0 wMstS bNkKFC dsowZP jZNcco bhxycR">
                    <svg
                      aria-label="verified-icon"
                      className="sc-9c65691d-0 hDXJCP sc-abc3a873-7 gFYqjx f-w"
                      fill="none"
                      viewBox="0 0 43 46"
                    >
                      <path
                        clip-rule="evenodd"
                        d="M23.6741 2.0787C22.3933 0.494275 19.9777 0.494276 18.6969 2.0787L16.932 4.26193C16.1363 5.24636 14.835 5.66918 13.6126 5.3405L10.9015 4.61157C8.93402 4.08257 6.97973 5.50245 6.87485 7.5371L6.73034 10.3407C6.66518 11.6049 5.86095 12.7118 4.67879 13.1644L2.05704 14.1682C0.154385 14.8967 -0.592088 17.1941 0.519004 18.9018L2.05002 21.255C2.74036 22.316 2.74036 23.6842 2.05002 24.7453L0.519003 27.0984C-0.592088 28.8061 0.154384 31.1035 2.05704 31.832L4.67879 32.8358C5.86095 33.2884 6.66518 34.3954 6.73034 35.6595L6.87485 38.4631C6.97973 40.4978 8.93402 41.9177 10.9015 41.3887L13.6126 40.6597C14.835 40.3311 16.1363 40.7539 16.932 41.7383L18.6969 43.9215C19.9777 45.506 22.3933 45.506 23.6741 43.9215L25.4389 41.7383C26.2347 40.7539 27.536 40.3311 28.7584 40.6597L31.4695 41.3887C33.4369 41.9177 35.3912 40.4978 35.4961 38.4631L35.6406 35.6595C35.7058 34.3953 36.51 33.2884 37.6922 32.8358L40.3139 31.832C42.2166 31.1035 42.963 28.8061 41.8519 27.0984L40.3209 24.7453C39.6306 23.6842 39.6306 22.316 40.3209 21.255L41.8519 18.9018C42.963 17.1941 42.2166 14.8967 40.3139 14.1682L37.6922 13.1644C36.51 12.7118 35.7058 11.6049 35.6406 10.3407L35.4961 7.53709C35.3912 5.50244 33.4369 4.08257 31.4695 4.61157L28.7584 5.3405C27.536 5.66918 26.2347 5.24636 25.4389 4.26193L23.6741 2.0787ZM32.8789 17.6449L29.9646 14.7307L18.6075 26.0878L14.1075 21.5878L11.1933 24.5021L18.6075 31.9163L32.8789 17.6449Z"
                        fill="white"
                        fill-rule="evenodd"
                      ></path>
                    </svg>
                  </div>
                </div>
                {/* <div className="d-f a-i-c m-t-2 c-w ">
                  <div className="bg_clr_time w-5 b-r-10 pa-0_5 t-a-c m-r-1  ">
                    <span className="f-w-600 f-s-1_25">0</span> <br /> days
                  </div>
                  <div className="bg_clr_time w-5 b-r-10 pa-0_5 t-a-c m-r-1 ">
                    <span className="f-w-600 f-s-1_25">16</span> <br /> hrs
                  </div>
                  <div className="bg_clr_time w-5 b-r-10 pa-0_5 t-a-c m-r-1 ">
                    <span className="f-w-600 f-s-1_25">4</span> <br /> mins
                  </div>
                  <div className="bg_clr_time w-5 b-r-10 pa-0_5 t-a-c  ">
                    <span className="f-w-600 f-s-1_25">30</span> <br /> secs
                  </div>
                </div> */}
                <div className="d-f a-i-c "></div>
              </div>
            </div>
            <div className="sc-57ceaf99-0 sc-630fc9ab-0 sc-99655001-0 sc-abc3a873-4 kHrhvD bNkKFC dsowZP PpLlC">
              <div className="sc-57ceaf99-0 sc-630fc9ab-0 sc-8902b875-0 dcdPIr bNkKFC byWHnL">
                <div className="media-greaterThanOrEqual-lg">
                  <div className="sc-57ceaf99-0 sc-630fc9ab-0 kHrhvD bNkKFC">
                    <div className="media-greaterThanOrEqual-md">
                      <div className="sc-57ceaf99-0 sc-630fc9ab-0 vjlYf bNkKFC">
                        {/* <div className="sc-57ceaf99-0 sc-630fc9ab-0 sc-5e25fb2f-0 kHrhvD bNkKFC cYKHmf">
                          <div className="">
                            <BsGlobe className="f-w f-s-2 f-w-600 m-r-2 m-q-b-d-n c-p" />
                          </div>
                          <div className="">
                            <BsDiscord className="f-w f-s-2 f-w-600 m-r-2 m-q-b-d-n c-p" />
                          </div>
                          <div className="">
                            <AiFillInstagram className="f-w f-s-2 f-w-600 m-r-2 m-q-b-d-n c-p" />
                          </div>
                          <div className="">
                            <AiOutlineTwitter className="f-w f-s-2 f-w-600 m-r-2 m-q-b-d-n c-p" />
                          </div>
                          <div className="">
                            <BiUpload className="f-w f-s-2 f-w-600 m-r-2  c-p" />
                          </div>
                          <div className="">
                            <BsThreeDots className="f-w f-s-2 f-w-600  c-p" />
                          </div>
                        </div> */}
                      </div>
                    </div>
                    <div className="sc-57ceaf99-0 sc-8902b875-2 kHrhvD jKsCOk"></div>
                  </div>
                </div>
                <div
                  className="sc-57ceaf99-0 kHrhvD"
                  aria-expanded="false"
                ></div>
                <div
                  className="sc-57ceaf99-0 fVFJgo"
                  aria-expanded="true"
                ></div>
              </div>
            </div>
          </div>
          <div className="sc-57ceaf99-0 sc-abc3a873-1 kHrhvD fsQJsv"></div>
        </div>
      </div>
      {/* ---------------second-section--------------- */}
      <div className="">
        <div className="pa-2">
          <div className="row ">
            <div className="col-12 col-sm-12 col-md-6 m-t-3">
              <Drop_swiper_slider
                logo={logo}
                images={images}
              />
            </div>
            <div className="col-12 col-sm-12 col-md-6 m-t-3">
              <div className="m-b-2 f-s-2 f-w-600">
                About this collection
              </div>
              {/* <div className="m-b-2 ">About this collection</div> */}
              <div className="m-b-2 ">{descriptions}</div>

              <div className="m-b-1 d-f a-i-c j-c-s-b m-b-1">
                <div className=" f-w-600">
                  {(Number(nftTotalSupply) /
                    Number(nftMaxSupply)) *
                    100}
                  % minted
                </div>
                <div className=" ">
                  {nftTotalSupply} / {nftMaxSupply}
                </div>
              </div>
              <div className="m-b-2 ">
                <ProgressBar
                  now={
                    (Number(nftTotalSupply) /
                      Number(nftMaxSupply)) *
                    100
                  }
                />
              </div>
              <div className="box_shadow pa-1_5 m-t-2 b-r-10">
                <div className="f-s-2 f-w-600 m-b-1">
                  Mint now
                </div>
                <div className="m-y-1">
                  <input
                    type="amount"
                    onChange={(e) =>
                      setMintAmount(e.target.value)
                    }
                    className="recordion-in"
                  />
                </div>
                <div className="d-f a-i-c w-100 ">
                  <button
                    className="gray_hover   w-100    b-n b-r-10 p-y-1 c-w f-w-600 para_clr"
                    onClick={() => MintAirDrop()}
                  >
                    Mint
                  </button>
                </div>
              </div>
              {owner == UserWallet ? (
                <div className="box_shadow pa-1_5 m-t-2 b-r-10">
                  {/* <div className="f-s-2 f-w-600 m-b-1">ADMIN</div> */}
                  {!isrevealed ? (
                    <>
                      <div className="f-s-1 f-w-300  m-b-1">
                        Reveal URI
                      </div>
                      <div className="m-y-1">
                        <input
                          type="text"
                          placeholder="URI"
                          onChange={(e) =>
                            setURILink(e.target.value)
                          }
                          className="recordion-in"
                        />
                      </div>
                      <div className="d-f a-i-c m-y-1 w-100 ">
                        <button
                          className="gray_hover   w-100   b-n b-r-10 p-y-1 c-w f-w-600 para_clr"
                          onClick={() => updateURI(urilink)}
                        >
                          Update URI
                        </button>
                      </div>
                    </>
                  ) : (
                    ""
                  )}

                  {whitelisted ? (
                    <>
                      <div className="f-s-1 f-w-300 m-b-1 m-t-3">
                        WhiteList wallets comma seperated
                      </div>
                      <div className="m-y-1">
                        <input
                          type="text"
                          placeholder="wallets"
                          className="recordion-in"
                        />
                      </div>
                      <div className="d-f a-i-c m-y-1 w-100 ">
                        <button
                          className="gray_hover   w-100   b-n b-r-10 p-y-1 c-w f-w-600 para_clr"
                          onClick={() => MintAirDrop()}
                        >
                          Whitelist
                        </button>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                  {whitelisted ? (
                    <div className="d-f a-i-c m-y-1 w-100 ">
                      <button
                        className="gray_hover   w-100   b-n b-r-10 p-y-1 c-w f-w-600 para_clr"
                        onClick={() =>
                          openMintingForPublic()
                        }
                      >
                        Open Public Minting
                      </button>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Toaster />
    </div>
  );
}

export default Drop;
