import React, { useEffect, useState } from "react";
import "./MyCollections.css";
import axios from "axios";
import Footer from "../Footer";
import Form from "react-bootstrap/Form";

import Typography from "@mui/material/Typography";

import LanguageIcon from "@mui/icons-material/Language";
import TelegramIcon from "@mui/icons-material/Telegram";
import Micons from "./CollectionsImages/capiM.png";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";
import Card from "react-bootstrap/Card";
import Navbarnft_global from "../Navbars/Navbarnft_global";

import Storyimage1 from "../../Images/storytellingimg1.png";
import Storysmallimg1 from "../../Images/tabimage3.png";

import { Link } from "react-router-dom";
import { get_Marketplace_contract } from "./../../Web3/Contract";

import toast, { Toaster } from "react-hot-toast";
import { Backdrop, CircularProgress, Grid } from "@mui/material";
import { FallingLines } from "react-loader-spinner";
import { RxCross1 } from "react-icons/rx";
import { create as ipfsHttpClient } from "ipfs-http-client";
import { useStoreState } from "easy-peasy";
import { AiOutlineMedium } from "react-icons/ai";

const notify = (msg) => toast.success(msg);
const warning = (msg) => toast.error(msg);

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#6A6A6A",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

function MyCollections({
  UserWallet,
  URL,
  catogeries,
  IPFS_image_baseURL,
  signMessage,
  logout,
}) {
  const [open, setOpen1] = useState(false);
  const authorization = useStoreState((state) => state.authorization);
  const ipfs_Store_url = useStoreState((state) => state.ipfs_Store_url);

  const [selectedFile5, setSelectedFile5] = useState();

  const changeHandler5 = (event) => {
    setSelectedFile5(event.target.files[0]);
  };

  const handleSubmission5 = async () => {
    const formData = new FormData();
    formData.append("file", selectedFile5);
    try {
      const ipfs = ipfsHttpClient({
        url: ipfs_Store_url,
        headers: {
          authorization,
        },
      });
      console.log(formData);
      const response = await ipfs.add(selectedFile5);
      console.log(response);
      setLogoHash(response.path);
    } catch (error) {
      console.log(error);
    }
  };

  const [selectedFile2, setSelectedFile2] = useState();
  const changeHandler2 = (event) => {
    setSelectedFile2(event.target.files[0]);
  };

  const handleSubmission2 = async () => {
    const formData = new FormData();
    formData.append("file", selectedFile2);
    try {
      const ipfs = ipfsHttpClient({
        url: ipfs_Store_url,
        headers: {
          authorization,
        },
      });
      const response = await ipfs.add(selectedFile2);

      console.log(response.path);
      setFeatureHash(response.path);
    } catch (error) {
      console.log(error);
    }
  };

  const [selectedFile7, setSelectedFile7] = useState();
  const changeHandler7 = (event) => {
    setSelectedFile7(event.target.files[0]);
  };

  const handleSubmission7 = async () => {
    const formData = new FormData();
    formData.append("file", selectedFile7);
    try {
      const ipfs = ipfsHttpClient({
        url: ipfs_Store_url,
        headers: {
          authorization,
        },
      });
      const response = await ipfs.add(selectedFile7);
      console.log(response.path);
      setBannerHash(response.path);
    } catch (error) {
      console.log(error);
    }
  };

  {
    /* ----------------Pinata---end-------------- */
  }

  const [collectionName, setCollectionName] = useState();
  const [url, setURL] = useState("");
  const [categry, setCategry] = useState("Art");
  const [token, setToken] = useState(
    "0x75498d0c86a449b4b5e877EE366E16C0779f7E66"
  );
  const [description, setDescription] = useState(" ");
  const [chain, setChain] = useState();
  const [telegramlink, setTelegramLink] = useState("");
  const [mediumlink, setMediumLink] = useState("");
  const [website, setWebsite] = useState("");
  const [bannerhash, setBannerHash] = useState();
  const [featurehash, setFeatureHash] = useState();
  const [logohash, setLogoHash] = useState();
  const [fee, setFee] = useState(0);
  const [royaltywallet, setRoyaltyWallet] = useState();

  useEffect(() => {
    const init = async () => {
      const contract = await get_Marketplace_contract();
      // console.log(contract);
    };
    init();
  }, []);

  const sendCollection = async () => {
    setOpen1(true);
    try {
      const contract = await get_Marketplace_contract();
      if (!bannerhash) {
        warning("Banner image is not uploaded");
        setOpen1(false);
        return;
      }
      if (!featurehash) {
        warning("Feature image is not uploaded");
        setOpen1(false);
        return;
      }
      if (!logohash) {
        warning("Logo image is not uploaded");
        setOpen1(false);
        return;
      }
      if (!collectionName) {
        warning("Invalid collection name");
        setOpen1(false);
        return;
      }
      setOpen1(true);
      // const data1 = await contract.methods.addCollection(fee, royaltywallet).send({ from: UserWallet });
      // const data2 = await contract.methods.getCollectionsByOwner(UserWallet).call();
      // if (data1.status) {
      //   console.log("collection ", data2);
      //   if (!data2[data2.length - 1]) {
      //     warning("Something went wrong, please try agin");
      //     setOpen1(false);
      //     return;
      //   }
      // }
      axios
        .post(`${URL}/makecollection`, {
          Creator: UserWallet,
          Creator_id: userId, // creator ID is not using in whole project yet but may be usefull later
          fee: fee,
          bannerImage: `${IPFS_image_baseURL}/${bannerhash}`,
          logoImage: `${IPFS_image_baseURL}/${logohash}`,
          featureImage: `${IPFS_image_baseURL}/${featurehash}`,
          name: collectionName,
          // url: url,
          royalty_address: royaltywallet,
          description: description,
          catogry: categry,
          website: website,
          telegram: telegramlink,
          medium: mediumlink,
          chain: chain,
          token: token,
        })
        .then((res) => {
          notify("Collection Created");
          setOpen1(false);
        })
        .catch((error) => {
          console.log(error);
          setOpen1(false);
        });
    } catch (error) {
      setOpen1(false);
      console.log(error);
    }
  };

  const [myAllCollection, setMyAllCollection] = useState();
  const [userId, setUserID] = useState();

  useEffect(() => {
    const init = async () => {
      axios
        .post(`${URL}/getuser`, {
          wallet: UserWallet,
        })
        .then((res) => {
          console.log("User Id ", res.data[0]._id);
          setUserID(res.data[0]._id);
        });

      axios
        .post(`${URL}/collectionbycreateor`, {
          wallet: UserWallet,
        })
        .then((res) => {
          setMyAllCollection(res.data);
        });
    };
    init();
  }, [UserWallet, open]);

  return (
    <>
      <Navbarnft_global
        signMessage={signMessage}
        logout={logout}
        UserWallet={UserWallet}
        URL={URL}
      />
      <div className="p-x-2 m-t-3 ">
        <div className=" ">
          <h1 className="f-s-3">My Collections</h1>
          <p className="">
            Create, curate, and manage collections of unique NFT’s to share and
            sell
          </p>
          <div className="m-t-1">
            <button
              type="button"
              class="btn btn-primary"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
              id="create-collection-button"
            >
              Create Collection
            </button>

            {/* ----------------collection-------modal------------------- */}

            <div
              class="modal fade"
              id="exampleModal"
              tabindex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog">
                <div class="modal-content">
                  <div class="modal-header">
                    <h1 class="modal-title fs-5" id="exampleModalLabel"></h1>

                    <RxCross1
                      class="c-p"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    />
                  </div>
                  <div class="modal-body">
                    <div className="">
                      <div>
                        <div className="row mycollections-sc">
                          <div className="col-lg-7 col-md-8 col-sm-12 col-12 pa-2">
                            <Typography
                              id="modal-modal-title"
                              variant="h6"
                              component="h2"
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              Create Collection <div></div>
                            </Typography>
                            <Typography
                              id="modal-modal-description"
                              sx={{ mt: 2 }}
                            >
                              <strong style={{ color: "red" }}>*</strong>
                              Required Fields
                            </Typography>

                            <div className="section-collection">
                              <Typography className="highligh-section">
                                Logo image
                                <strong style={{ color: "red" }}>*</strong>
                                {/* {logohash.slice(0, 5)} */}
                              </Typography>
                              <Typography className="content-form f-s-0_8">
                                This image will also be used for navigation. 350
                                x 350 recommended.
                              </Typography>
                              <div className="row">
                                <div className="col-12">
                                  <div>
                                    <div className="m-q-d-f a-i-c">
                                      {" "}
                                      <div className="featured-img-box2 my-3 m-r-2">
                                        <div className="fe-bo">
                                          <Typography className="tag-image-name-drop">
                                            <input
                                              type="file"
                                              onChange={changeHandler5}
                                            />
                                          </Typography>
                                        </div>
                                      </div>{" "}
                                      {logohash ? (
                                        <div className="highligh-section">
                                          <img
                                            src={`https://docy.infura-ipfs.io/ipfs/${logohash}`}
                                            className="m-q-w-13 h-7 b-r-20  "
                                          />
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="section-collection">
                              <div>
                                <Typography className="highligh-section">
                                  Featured Image
                                  {/* {featurehash} */}
                                </Typography>
                                <Typography className="content-form f-s-0_8">
                                  This image will be used for featuring your
                                  collection on the homepage, category pages, or
                                  other promotional areas of origin. 600 x 400
                                  recommended.
                                </Typography>
                              </div>
                              <div>
                                <div className="m-q-d-f a-i-c">
                                  <div className="featured-img-box2 my-3 m-r-2">
                                    <div className="fe-bo">
                                      {/*       <img src={Dragimg} alt="" /> */}
                                      <Typography className="tag-image-name-drop">
                                        {/*     Drop image here
                                <br />
                                or browse */}

                                        <input
                                          type="file"
                                          onChange={changeHandler2}
                                        />
                                      </Typography>
                                    </div>
                                  </div>
                                  {featurehash ? (
                                    <Typography className="highligh-section">
                                      <img
                                        src={`https://docy.infura-ipfs.io/ipfs/${featurehash}`}
                                        className="m-q-w-13 h-7 b-r-20  "
                                      />
                                    </Typography>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="section-collection">
                              <div>
                                <Typography className="highligh-section">
                                  Banner Image
                                  {/* {bannerhash} */}
                                </Typography>
                                <Typography className="content-form f-s-0_8">
                                  This image will appear at the top of your
                                  collection page. Avoid including too much text
                                  in this banner image, as the dimensions change
                                  on different devices. 1400 x 350 recommended.
                                </Typography>
                              </div>
                              <div>
                                <div className="m-q-d-f a-i-c">
                                  <div className="featured-img-box2 my-3 m-r-2">
                                    <div className="fe-bo">
                                      {/*       <img src={Dragimg} alt="" /> */}
                                      <Typography className="tag-image-name-drop">
                                        {/*     Drop image here
                                    <br />
                                    or browse */}

                                        <input
                                          type="file"
                                          onChange={changeHandler7}
                                        />
                                      </Typography>
                                    </div>
                                  </div>
                                  {bannerhash ? (
                                    <Typography className="highligh-section">
                                      <img
                                        src={`https://docy.infura-ipfs.io/ipfs/${bannerhash}`}
                                        className="m-q-w-13 h-7 b-r-20  "
                                      />
                                    </Typography>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </div>
                            <button
                              className="save-bu22 t-a-r m-l-0"
                              onClick={() => {
                                handleSubmission5();
                                handleSubmission2();
                                handleSubmission7();
                              }}
                            >
                              Submit
                            </button>
                            <div className="section-collection">
                              <div>
                                <Typography className="highligh-section">
                                  Name{" "}
                                  <strong style={{ color: "red" }}>*</strong>
                                </Typography>
                                <input
                                  placeholder="Example: Treasures of the Sea"
                                  className="recordion-in"
                                  value={collectionName}
                                  onChange={(e) =>
                                    setCollectionName(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                            {/* <div className="section-collection">
                              <div>
                                <Typography className="highligh-section">
                                  URL
                                </Typography>
                                <Typography className="content-form f-s-0_8">
                                  Customize your URL on origin. Must only
                                  contain lowercase letters, numbers, and
                                  hyphens.
                                </Typography>
                                <input
                                  placeholder="therecordianmusic"
                                  value={url}
                                  className="recordion-in my-3"
                                  onChange={(e) => setURL(e.target.value)}
                                />
                              </div>
                            </div> */}
                            <div className="section-collection">
                              <div>
                                <Typography className="highligh-section">
                                  Royalty Fee
                                </Typography>

                                <input
                                  placeholder="fee"
                                  value={fee}
                                  className="recordion-in my-3"
                                  onChange={(e) => setFee(e.target.value)}
                                />
                              </div>
                            </div>
                            <div className="section-collection">
                              <div>
                                <Typography className="highligh-section">
                                  Royalty Receiver Wallet
                                </Typography>

                                <input
                                  placeholder="wallet address"
                                  value={royaltywallet}
                                  className="recordion-in my-3"
                                  onChange={(e) =>
                                    setRoyaltyWallet(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                            <div className="section-collection">
                              <div>
                                <Typography className="highligh-section">
                                  Description
                                </Typography>
                                <Typography className="content-form f-s-0_8">
                                  <strong style={{ color: "#FEC200" }}>
                                    Markdown
                                  </strong>{" "}
                                  syntax is supported. 0 of 1000 characters
                                  used.
                                </Typography>
                                <textarea
                                  className="text-area-mycollections my-4"
                                  value={description}
                                  onChange={(e) =>
                                    setDescription(e.target.value)
                                  }
                                ></textarea>
                              </div>
                            </div>
                            <div className="section-collection">
                              <div>
                                <Typography className="highligh-section">
                                  Add Category
                                </Typography>
                                <Typography className="content-form f-s-0_8">
                                  Adding a category will help make your item
                                  discoverable on origin.
                                </Typography>
                                <Form.Select
                                  aria-label="Default select example"
                                  onChange={(e) => setCategry(e.target.value)}
                                  className="m-t-1"
                                >
                                  {catogeries &&
                                    catogeries.map((res) => {
                                      return (
                                        <option value={`${res}`}>{res}</option>
                                      );
                                    })}
                                </Form.Select>
                                {/*   <Dropdown>
                                <Dropdown.Toggle
                                  variant="success"
                                  id="dropdown-basic"
                                  className="cate-bu my-3 b-c-t"
                                >
                                + Add category
                                 
                                </Dropdown.Toggle>
              
                                <Dropdown.Menu className="pa-1">
                                  <Dropdown.Item href="#/action-1"></Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                              */}
                              </div>
                            </div>
                            <div className="section-collection">
                              <div>
                                <Typography className="highligh-section">
                                  Links
                                </Typography>
                                <div className="position-relative">
                                  <LanguageIcon className="position-absolute icons-input" />{" "}
                                  <input
                                    className="link-input1 p-l-3"
                                    placeholder="yoursite.io"
                                    value={website}
                                    onChange={(e) => setWebsite(e.target.value)}
                                  />
                                  <br />
                                 <AiOutlineMedium className="position-absolute icons-input f-s-1_5 "/>
                                  <input
                                    className="link-input p-l-3"
                                    placeholder="https://www.medium.com/@mediumhandle"
                                    value={mediumlink}
                                    onChange={(e) =>
                                      setMediumLink(e.target.value)
                                    }
                                  />
                                  <br />
                                  <TelegramIcon className="position-absolute icons-input" />{" "}
                                  <input
                                    className="link-input3 p-l-3"
                                    placeholder="https://t.me/"
                                    value={telegramlink}
                                    onChange={(e) =>
                                      setTelegramLink(e.target.value)
                                    }
                                  />
                                </div>
                              </div>
                            </div>

                            <div>
                              {/* <div className="section-collection">
                                <Typography className="highligh-section">
                                  Blockchain
                                </Typography>
                                <Typography className="content-form mb-3">
                                  Select the blockchain where you'd like new
                                  items from this collection to be added by
                                  default. info
                                </Typography>
                                <div>
                                  <Form.Select
                                    aria-label="Default select example"
                                    className="mt-3"
                                    onChange={(e) => setChain(e.target.value)}
                                    disabled={true}
                                  >
                                    <option value="5">Goerli</option>
                                    <option value="56">BNB smart chain</option>
                                  </Form.Select>
                                </div>
                              </div> */}
                              <div className="section-collection">
                                <div>
                                  <Typography className="highligh-section">
                                    Payment Tokens
                                  </Typography>
                                  <Typography className="content-form f-s-0_8">
                                    These tokens can be used to buy and sell
                                    your items.
                                  </Typography>

                                  <Form.Select
                                    aria-label="Default select example"
                                    className="mt-3"
                                    onChange={(e) => setToken(e.target.value)}
                                  >
                                    <option value="0x75498d0c86a449b4b5e877EE366E16C0779f7E66">
                                      GCS
                                    </option>
                                    <option value="0xC801a04cf093Da9b6670feaCECCAC7235A3Ec8B9">
                                      MAAL
                                    </option>
                                  </Form.Select>
                                </div>
                              </div>
                            </div>
                            {/* <div className="section-collection">
                              <div className="row">
                                <div className="col-8">
                                  <Typography className="highligh-section">
                                    Explicit & sensitive content
                                  </Typography>
                                  <Typography className="content-form f-s-0_8">
                                    Set this collections as explicit & sensitive
                                    content
                                  </Typography>
                                </div>
                                <div className="col-4">
                                  <span className="ios-check-button">
                                    <FormControlLabel
                                      control={
                                        <IOSSwitch
                                          sx={{ m: 1 }}
                                          defaultChecked
                                        />
                                      }
                                    />
                                  </span>
                                </div>
                              </div>
                            </div> */}
                          </div>
                          <div className="col-lg-4 col-md-4 col-sm-12 col-12"></div>
                          <div className="col-lg-7 col-md-8 col-sm-12 m-b-6">
                            <div className="button-section">
                              <button
                                className="save-bu22"
                                onClick={() => sendCollection()}
                              >
                                Create
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* ----------------nft-------modal------------------- */}

            <div
              class="modal fade"
              id="exampleModalNft"
              tabindex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog ">
                <div class="modal-content">
                  <div class="modal-header">
                    <h1 class="modal-title fs-5" id="exampleModalLabel"></h1>
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div class="modal-body pa-2"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* -----------card----------- */}

      <div className="container-kws">
        <Grid container>
          {myAllCollection &&
            myAllCollection.map((res) => {
              return (
                <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                  <Card className="m-y-2">
                    <Link to={`/collection/${res.name}/${res.Collection_id}`}>
                      <Card.Img variant="top" src={res.bannerImage} />
                      <Card.Body>
                        <Card.Title className="card-body12">
                          <span>
                            {" "}
                            <img
                              src={res.logoImage}
                              alt=""
                              style={{ display: "inline-flex" }}
                              className="card-image1"
                            />{" "}
                          </span>
                          <span className="text32">{res.name}</span>
                          <span className="tick-icons"></span>
                        </Card.Title>
                      </Card.Body>
                    </Link>
                  </Card>
                </Grid>
              );
            })}
        </Grid>
      </div>

      {myAllCollection === undefined ? (
        <div className="p-y-7 p-l-3">
          <FallingLines
            color="#B58B1D"
            width="100"
            visible={true}
            ariaLabel="falling-lines-loading"
          />
        </div>
      ) : myAllCollection.length == 0 ? (
        <div className="row setting-collect">
          <p className="content-section11">No item to display</p>
        </div>
      ) : (
        ""
      )}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Toaster />
      <Footer />
    </>
  );
}

export default MyCollections;
