import React from "react";
import { BsDiscord } from "react-icons/bs";
import { BsFacebook } from "react-icons/bs";
import { BsTwitter } from "react-icons/bs";
import { FaTelegramPlane } from "react-icons/fa";
import logo from "../Images/pansea_white.png";
import { BsYoutube } from "react-icons/bs";
import { FiInstagram } from "react-icons/fi";
import "./Footer.css";
import { Link } from "react-router-dom";

function Footer(props) {
  return (
    <>
      <div className="Footer-left-spacing m-t-5">
        <div className="p-x-2 p-y-5">
          <div className="row" id="foter21">
            <div
              className="col-lg-7 col-md-12 col-sm-12 col-12"
              id="footer-posted-margin-bottom"
            >
              <h5 className="keep-head">Stay in the loop</h5>
              <p className="subs-para">
                Join our mailing list to stay in the loop with our newest
                feature releases, NFT drops, and tips and tricks for navigating
                PanSea.
              </p>
              <div className="input-group1 mb-3 m-t-1">
                <input
                  type="email"
                  className="form-control22"
                  placeholder="Enter email address..."
                  aria-label="Recipient's username"
                  aria-describedby="button-addon2"
                />
                <button
                  className="search signup"
                  type="button"
                  id="button-addon2"
                >
                  Sign up
                </button>
              </div>
            </div>
            <div
              className="col-lg-5 col-md-12 col-sm-12 col-12"
              id="footer-posted-margin-bottom"
            >
              <h5 className="join-header">Join the community</h5>
              <div>
                <ul className="icon">
                  <li className="twitter-icons">
                    <BsDiscord className="b-c-t f-w" />
                  </li>
                  <li className="twitter-icons">
                    <BsFacebook className="b-c-t f-w" />
                  </li>
                  <li className="twitter-icons">
                    <BsTwitter className="b-c-t f-w" />
                  </li>
                  <li className="twitter-icons">
                    <FaTelegramPlane className="b-c-t f-w" />
                  </li>

                  <li className="twitter-icons">
                    <BsYoutube className="b-c-t f-w" />
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div>
            <div className="row m-t-3">
              <div className="col-lg-5 col-md-12 col-sm-12 col-12 footer-pansea footer-section">
                <img src={logo} className="w-10 m-b-2" />
                <p className="origins-para">
                  The world’s first and largest digital marketplace for crypto
                  collectibles and non-fungible tokens (NFTs). Buy, sell, and
                  discover exclusive digital items.
                </p>
              </div>
              <div className="col-lg col-md col-sm-6 col-6 footer-section">
                {" "}
                <h5 className="list-heading">Marketplace</h5>
                <ul className="footer2-list">
                  <li>All NFTs</li>
                  <li>Art</li>
                  <li>Collectibles</li>
                  <li>Photography</li>
                  <li>Recordian</li>
                  <li>Videos</li>
                  <li>Trending</li>
                </ul>
              </div>
              <div className="col-lg col-md col-sm-6 col-5 profile-fo footer-section">
                {" "}
                <h5 className="list-heading">My Profile</h5>
                <ul className="footer2-list">
                  <li>Overview</li>
                  <li>Favourites</li>
                  <li>Watchlist</li>
                  <li>My Collections</li>
                  <li>Settings</li>
                </ul>
              </div>
              <div className="col-lg col-md col-sm-12 col-12 footer-section">
                {" "}
                <h5 className="list-heading">Resources</h5>
                <ul className="footer2-list">
                  <li>Docs</li>
                  <li>Partners</li>
                  <li>Advertisement</li>
                  <li>Newsletter</li>
                  <li>Socials</li>
                </ul>
              </div>
            </div>
          </div>{" "}
        </div>
        <hr className="para_clr m-b-0 p-b-0 " />

        <div className="m-q-b-d-b a-i-c j-c-s-b t-a-c f-s-0_8 p-x-2 p-y-2">
          <div className="p-y-0_5">© 2023 PanSea Inc</div>
          <div className="p-y-0_5">
            {" "}
            <Link to="/privacy">Privacy Policy</Link> &nbsp; &nbsp;{" "}
            <Link to="/terms">Terms of Service</Link>{" "}
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
