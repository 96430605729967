import React, { useState } from 'react';
import { create as ipfsHttpClient } from "ipfs-http-client"
import axios from 'axios';

const ImageUploader = () => {
  const [file, setFile] = useState(null);
  const authorization = "Basic " + window.btoa("2DAMudhgOkN62ZdPtEDTNh7ugcl" + ":" + "aed666c74886176af0e63a2594f14b85");
  const handleFileInputChange = (event) => {
    setFile(event.target.files[0]);
  };

  const uploadImage = async () => {
    try {
      const formData = new FormData();
      formData.append('file', file);
      console.log(file)
      const ipfs = ipfsHttpClient({
        url: "https://ipfs.infura.io:5001/api/v0",
        headers:{
          authorization
        }
      })
      const response = await ipfs.add(file);

      // const response = await axios.post('https://ipfs.infura.io:5001/api/v0/add', formData, {
      //   headers: {
      //     'Content-Type': 'multipart/form-data',
      //   },
      // });

      const cid = response;
      console.log('Image uploaded to IPFS:', cid);
    } catch (error) {
      console.error('Error uploading image:', error);
    }
  };

  return (
    <div>
      <h2>Image Uploader</h2>
      <input type="file" onChange={handleFileInputChange} />
      <button onClick={uploadImage} disabled={!file}>
        Upload Image
      </button>
      {/* <img src={} */}
    </div>
  );
};

export default ImageUploader;
